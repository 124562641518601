const host = window.location.hostname;



let images;
let base_url;
let path;
let img_path;
let imge;
let assets;
let root;
let admin_path;
let path_realestate;


if (host == 'localhost' || host == 'testbo.businessi.ai') {

 
  // base_url = "https://api.businessi.ai/api/";
  // path = "https://api.businessi.ai/";
  // img_path = "https://api.businessi.ai/Personal/MemberImages/";
  // imge = "https://api.businessi.ai/personal/memberimages/kyc/";
  // assets = "https://api.businessi.ai/assets/";
  // root = "https://api.businessi.ai/";


    base_url = "https://testapi.businessi.ai/api/";
    path = "https://testapi.businessi.ai/";
    img_path = "https://testapi.businessi.ai/Personal/MemberImages/";
    imge = "https://testapi.businessi.ai/personal/memberimages/kyc/";
    assets = "https://testapi.businessi.ai/assets/";
    root = "https://testapi.businessi.ai/";


    // base_url = "http://localhost:52476/api/";
    // path = "http://localhost:52476/";
    // img_path = "http://localhost:52476/Personal/MemberImages/";
    // imge = "http://localhost:52476/personal/memberimages/kyc/";
    // assets = "http://localhost:52476/assets/";
    // root = "http://localhost:52476/";
    // admin_path = "http://localhost:52476/";
    // path_realestate = "http://localhost:52476//images/RealEstate/";


}
else
{
  base_url = "https://api.businessi.ai/api/";
  path = "https://api.businessi.ai/";
  img_path = "https://api.businessi.ai/Personal/MemberImages/";
  imge = "https://api.businessi.ai/personal/memberimages/kyc/";
  assets = "https://api.businessi.ai/assets/";
  root = "https://api.businessi.ai/";
}

const config = {
  base_url,
  path,
  img_path,
  imge,
  assets,
  root,
  admin_path,
  images,
  date_format: "DD/MM/YYYY",
  date_format_input: "YYYY-MM-DD",
};



// const config = {
//   // base_url: "http://localhost:8000/api",
//   // MEDIA_ROOT_URL: "http://localhost:8000/",

//   date_format: "DD/MM/YYYY",
//   date_format_input: "YYYY-MM-DD",


//   // base_url: "http://localhost:52476/api/",
//   // path: "http://localhost:52476/",
//   // img_path: "http://localhost:52476/Personal/MemberImages/",
//   // imge:"http://localhost:52476/personal/memberimages/kyc/",
//   // assets: "http://localhost:52476/assets/",
//   // root: "http://localhost:52476/",



// changes in config file

// };
export default config;
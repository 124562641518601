import React from 'react'
import TransactionsMenu from './TransactionsMenu'
import AdminRightsCheck from '../AdminRightsCheck'

const Transactions = () => {
  return (
    <>
      <TransactionsMenu />
      <AdminRightsCheck />
      <div className="admin-content">
        <div className="row align-items-center">
          <div className="col-md-6">
            <div className="d-title m-0">Manage Transactions</div>
          </div>
        </div>
      </div>




    </>
  )
}

export default Transactions
import React, { useEffect, useState } from "react";
import axios from "axios";
import config from "../../../Config";
import Pagination from "../../member/General/Pagination";
import { NavLink } from "react-router-dom";
import { useNavigate, useParams } from 'react-router-dom';
import Spinner from "react-bootstrap/Spinner";
import AdminRightsCheck from "../AdminRightsCheck";
import dayjs from "dayjs";

function AdminReferral(props) {


  const [AccessKey, setAccessKey] = useState(localStorage.getItem("AdminAccessKey"));
  const [UserID, setUserID] = useState(localStorage.getItem("AdminID"));

  const [loading, setLoading] = useState(false);

  const [members, setMembers] = useState([]);

  // pagination 
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);

  let { id } = useParams()

  let navigate = useNavigate();

  useEffect(() => {
    document.title = "Referral";
    setLoading(true);
    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      ID: id,
      pageNo: pageNo,
      pageSize: pageSize,
    };

    // console.log(data);

    var api_config = {
      method: 'post',
      url: config.base_url + 'Users/GetReferralList',
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(api_config)
      .then(function (response) {
        // console.log(response.data,'response');
        if (response.data.status_code == 1) {
          setMembers(response.data.ListofRecords);
          setTotalRecords(response.data.totalRecords);
          setLoading(false);

        }
        else {
          setLoading(false);
        }


      })
      .catch(function (error) {

      });

  }, [pageNo, pageSize]);


  const handlePageChange = (page) => {
    setPageNo(page);
  };


  const renderMembers = () => {
    if (members != null && members.length > 0) {
      let count = 1;
      return members.map((item, index) => {
        return (
          <>
            <tr key={index}>
              <td>{count++ + pageSize * (pageNo - 1)}</td>
              <td>{item.Email_Address}</td>
              <td>{dayjs(item.Created_Date).format("DD/MM/YYYY")}</td>
              <td>{item.Full_Name}</td>
              <td>{item.User_Name}</td>
              <td>{item.Investment_Balance}</td>
              <td>{item.Current_Balance}</td>
              <td>{item.ActiveWallet}</td>
            </tr>
          </>
        );
      });
    } else {
      return (
        <tr>
          <td colSpan="8">
            <h4
              className="mt-3 text-center"
              style={{ fontSize: "15px", color: "white" }}
            >
              No Record Exists
            </h4>
          </td>
        </tr>
      );
    }
  };

  return (
    <>
      <AdminRightsCheck />
      <div className="admin-content">
        <div className="row align-items-center">
          <div className="col-md-6">
            <div className="d-title m-0">Referral</div>
          </div>
          <div className="col-md-6 text-end">
            <div className="">
              <NavLink to="/admin/userstatistics/Total-user">
                <button type="button" className="button button-primary">
                  Back
                </button>
              </NavLink>
            </div>
          </div>
        </div>

        <div className="card card-table mb-5 mt-3">
          <div className="card-inner">
            <table className="table">
              <thead>
              <tr>
                  <th>Sr. No</th>
                  <th>Email</th>
                  <th>Date</th>
                  <th>Name</th>
                  <th>Usermame</th>
                  <th>Future Safe</th>
                  <th>Balance</th>
                  <th>Crew Reward</th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <tr>
                    <td colSpan={"8"} className="text-center">
                      <Spinner animation="border" role="status"></Spinner>
                    </td>
                  </tr>
                ) : (
                  <>{renderMembers()}</>
                )}
              </tbody>
            </table>
          </div>
        </div >
        {totalRecords > 10 && (
          <div className="pagination-wrap">
            <div className="row align-items-center">
              <div className="col-md-2 form_default">
                <div className="d-flex align-items-center">
                  <label className="me-2" style={{ fontSize: "15px" }}>
                    Page size
                  </label>
                  <div className="form-group">
                    <select
                      className="form-select"
                      style={{ width: "100px" }}
                      onChange={(e) => {
                        setPageNo(1);
                        setPageSize(parseInt(e.target.value));
                      }}
                    >
                      <option value="10" selected>
                        10
                      </option>
                      <option value="20">20</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                      <option value="500">500</option>
                    </select>
                  </div>
                </div>
              </div>

              <Pagination
                pageNo={pageNo}
                pageSize={pageSize}
                totalRecords={totalRecords}
                onPageChange={handlePageChange}
              />
            </div>
          </div>
        )}
      </div>

    </>
  );
}

export default AdminReferral
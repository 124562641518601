import React, { useState, useEffect } from "react";
import axios from "axios";
import config from "../../../Config";
import PageTitle from "../PageTitle";
import balance_transfer from "../../../assets/images/admin/icons/balance_transfer.png";
import free_balance from "../../../assets/images/admin/icons/free_balance.png";
import package_balance_transfer from "../../../assets/images/admin/icons/package_balance_transfer.png";
import promo_balance_transfer from "../../../assets/images/admin/icons/promo_balance_transfer.png";
import investus_bonus_transfer from "../../../assets/images/admin/icons/investus_bonus_transfer.png";
import recovered_balance from "../../../assets/images/admin/icons/recovered_balance.png";
import advance_balance_transfer from "../../../assets/images/admin/icons/advance_balance_transfer.png";
import icon6x from "../../../assets/images/admin/icons/6x.png";
import AdminRightsCheck from "../AdminRightsCheck";
import AdminCard from "../AdminCard";


   
   

function BalanceStatisticsDashboard() {
  return (
    <>
         
      <AdminRightsCheck />
      <PageTitle title="ADMIN BALANCE STATISTICS" />
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-3">
            <AdminCard
              link="#/"
              class="red"
              value="10000000$"
              label="TOTAL BALANCETRANSFER"
              icon={balance_transfer}
              progress="progress"
              progressbar="progress-bar"
              progressrole="progressbar"
              val="10%"
              arianow="10"
              ariamin="0"
              ariamax="100"
              progressval=""
            />
          </div>
          <div className="col-md-3">
            <AdminCard
              link="#/"
              class="red"
              value="70$"
              label="TOTAL BALANCE
TRANSFER"
              icon={free_balance}
              progress="progress"
              progressbar="progress-bar"
              progressrole="progressbar"
              val="10%"
              arianow="10"
              ariamin="0"
              ariamax="100"
              progressval=""
            />
          </div>
          <div className="col-md-3">
            <AdminCard
              link="#/"
              class="red"
              value="15$"
              label="TOTAL BALANCE
TRANSFER"
              icon={package_balance_transfer}
              progress="progress"
              progressbar="progress-bar"
              progressrole="progressbar"
              val="50%"
              arianow="10"
              ariamin="0"
              ariamax="100"
              progressval=""
            />
          </div>
          <div className="col-md-3">
            <AdminCard
              link="#/"
              class="red"
              value="3$"
              label="TOTAL BALANCE
TRANSFER"
              icon={promo_balance_transfer}
              progress="progress"
              progressbar="progress-bar"
              progressrole="progressbar"
              val="100%"
              arianow="10"
              ariamin="0"
              ariamax="100"
              progressval=""
            />
          </div>
          <div className="col-md-3">
            <AdminCard
              link="#/"
              class="red"
              value="90$"
              label="TOTAL BALANCE
TRANSFER"
              icon={investus_bonus_transfer}
              progress="progress"
              progressbar="progress-bar"
              progressrole="progressbar"
              val="10%"
              arianow="10"
              ariamin="0"
              ariamax="100"
              progressval=""
            />
          </div>
          <div className="col-md-3">
            <AdminCard
              link="#/"
              class="red"
              value="40$"
              label="TOTAL BALANCE
TRANSFER"
              icon={advance_balance_transfer}
              progress="progress"
              progressbar="progress-bar"
              progressrole="progressbar"
              val="90%"
              arianow="10"
              ariamin="0"
              ariamax="100"
              progressval=""
            />
          </div>
          <div className="col-md-3">
            <AdminCard
              link="#/"
              class="blue"
              value="110$"
              label="TOTAL RECOVERED
BALANCE"
              icon={recovered_balance}
              progress="progress"
              progressbar="progress-bar"
              progressrole="progressbar"
              val="80%"
              arianow="10"
              ariamin="0"
              ariamax="100"
              progressval=""
            />
          </div>
          <div className="col-md-3">
            <AdminCard
              link="#/"
              class="green"
              value="7$"
              label="TOTAL 6X
REVERSAL
"
              icon={icon6x}
              progress="progress"
              progressbar="progress-bar"
              progressrole="progressbar"
              val="10%"
              arianow="10"
              ariamin="0"
              ariamax="100"
              progressval=""
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default BalanceStatisticsDashboard;

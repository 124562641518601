import React from "react";
import { NavLink } from "react-router-dom";

function AdminCard(props) {
  return (
    <>
      <div className="admin-card">
        <NavLink to={props.link}>
          <div className={`card-inner ${props.class}`}>
            <div className="icon">
              <img src={props.icon} alt="" />
            </div>
            <p>{capitalizeFirstLetter(props.label)}</p>
            <div className="value">
              <h2>{props.value}</h2>
            </div>
            {/* <h6>{props.subheading} <span>{props.no}</span></h6> */}
          </div>
        </NavLink>
      </div>

      {/* <div className="admin-card">
        <div className={`card-inner ${props.class}`}>
          <NavLink to={props.link}></NavLink>
          <div className="admin-card-row">
            <img src={props.icon} alt="" />
            <div>
              <h2>{props.value}</h2>
              <p>{props.label}</p>
            </div>
          </div>

          <div className={`${props.progress}`} style={{ height: "4px" }}>
            <div
              className={`${props.progressbar}`}
              role={`${props.progressrole}`}
              style={{ width: `${props.val}` }}
              aria-valuenow={`${props.arianow}`}
              aria-valuemin={`${props.ariamin}`}
              aria-valuemax={`${props.ariamax}`}
            >
              {props.progressval}
            </div>
          </div>
        </div>
      </div > */}
    </>
  );
}

export default AdminCard;

export const capitalizeFirstLetter = (value) => {
  return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
}
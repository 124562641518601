import React from "react";
import Modal from "react-bootstrap/Modal";
import { Form } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import AdminRightsCheck from "../AdminRightsCheck";
const RankModel = ({
  show,
  handleAddRank,
  handleClose,
  validated,
  loadingUpdateBtn,
  selectedMember,
}) => {
  return (
    <>
       <AdminRightsCheck />
      <Modal className="theme-modal" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add New Rank For {selectedMember.User_Name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validated} onSubmit={handleAddRank}>
            <div style={{ padding: "20px" }}>
              <div className="form-group mb-3">
                <label>Rank Name</label>
                <input
                  type="text"
                  // defaultValue={selectedlistOfLedger.No_Of_Leaves}
                  name="Rank_Name"
                  className="form-control"
                  required
                />
              </div>

              {loadingUpdateBtn ? (
                <div className="text-center">
                  <Spinner animation="border" role="status"></Spinner>
                </div>
              ) : (
                <button type="submit" className="button button-primary w-100">
                  Add Rank
                </button>
              )}
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default RankModel;

import React, { useEffect, useState } from "react";
import axios from "axios";
import config from "../../../Config";
import { NavLink } from "react-router-dom";
import { useNavigate, useParams } from 'react-router-dom';
import AdminRightsCheck from "../AdminRightsCheck";
import NetworkMember from "./NetworkMember";
import Pagination from "../../member/General/Pagination";

function AdminNetwork(props) {


  const [AccessKey, setAccessKey] = useState(localStorage.getItem("AdminAccessKey"));
  const [UserID, setUserID] = useState(localStorage.getItem("AdminID"));

  const [loading, setLoading] = useState(false);

  const [members, setMembers] = useState([]);
  const [hasData, setHasData] = useState(false);

  // pagination 
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);

  let { id } = useParams()

  let navigate = useNavigate();

  useEffect(() => {
    document.title = "Network";
    setLoading(true);

    const data = {
      AccessKey: AccessKey,
      UserID: id,
      pageNo: pageNo,
      pageSize: pageSize,
      MemberType: "Admin"

    };

    // console.log(data);

    var api_config = {
      method: 'post',
      url: config.base_url + 'Members/ViewNetworkPage',
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(api_config)
      .then(function (response) {
        // console.log(response.data, 'response');
        if (response.data.status_code == 1) {
          setMembers(response.data.ListofMembers);
          setTotalRecords(response.data.totalRecords);
          setLoading(false);
          setHasData(true);
        }
        else {
          setLoading(false);
          setHasData(false);
        }

      })
      .catch(function (error) {

      });

  }, [pageNo, pageSize]);


  const handlePageChange = (page) => {
    setPageNo(page);
  };

  const renderMembers = () => {
    if (members != null && members.length > 0) {
      return (
        members.map((item, index) => {
          return (
            <>
              <NetworkMember
                key={index}
                photo={config.img_path + item.Img_File}
                name={item.Full_Name}
                email={item.Email_Address}
                type={item.Member_Type}
                amount={item.Investment_Balance}
                level={item.Hiera_IN_xx_NodeLevel}
                profit={item.ROI_Balance}
                profitReceived={item.Profit_Received}
                TeamCount={item.TeamCount}
              />
            </>

          )
        })
      )
    } else {
      return (
        <div className="text-center">
          <h4
            className="mt-5 text-center"
            style={{ fontSize: "15px", color: "white" }}
          >
            No Record Exists
          </h4>
        </div>
      );
    }
  }

  return (
    <>
      <AdminRightsCheck />
      <div className="admin-content">
        <div className="row align-items-center">
          <div className="col-md-6">
            <div className="d-title m-0">Network</div>
          </div>
          <div className="col-md-6 text-end">
            <div className="">
              <NavLink to="/admin/userstatistics/Total-user">
                <button type="button" className="button button-primary">
                  Back
                </button>
              </NavLink>
            </div>
          </div>
        </div>

        {
          loading ?
            <p className="m-5 text-center">Loading...</p>
            :
            <>
              <div className="network-list mt-3">
                {
                  hasData ?
                    <ul>
                      {renderMembers()}

                      {totalRecords > 10 && (
                        <div className="pagination-wrap">
                          <div className="row align-items-center">
                            <div className="col-md-2 form_default">
                              <div className="d-flex align-items-center">
                                <label className="me-2" style={{ fontSize: "15px" }}>
                                  Page size
                                </label>
                                <div className="form-group">
                                  <select
                                    className="form-select"
                                    style={{ width: "100px" }}
                                    onChange={(e) => {
                                      setPageNo(1);
                                      setPageSize(parseInt(e.target.value));
                                    }}
                                  >
                                    <option value="10" selected>
                                      10
                                    </option>
                                    <option value="20">20</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                    <option value="500">500</option>
                                    <option value="1000">1000</option>
                                  </select>
                                </div>
                              </div>
                            </div>

                            <Pagination
                              pageNo={pageNo}
                              pageSize={pageSize}
                              totalRecords={totalRecords}
                              onPageChange={handlePageChange}
                            />
                          </div>
                        </div>
                      )}
                    </ul>
                    :
                    <p className="m-5 p-5 text-center">No Record Exists</p>
                }

              </div>
            </>
        }
      </div>
    </>
  );
}

export default AdminNetwork
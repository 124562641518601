import React, { useState, useEffect } from "react";
import config from "../../../Config";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import { useNavigate } from "react-router-dom";
import { NavLink, Link } from "react-router-dom";
import dayjs from "dayjs";
import Pagination from "../../member/General/Pagination";
import { useParams } from "react-router-dom";
import AdminRightsCheck from "../AdminRightsCheck";
import { Form } from "react-bootstrap";
import { calculateColumnTotal } from "../../Common/Util";
import { NumberFormat } from "../../member/General/Functions";

function Initiated(props) {
    const userstatus = useParams();
    const userstatusVal = userstatus.userstatus;

    //console.log(userstatus.userstatus);

    const [AccessKey, setAccessKey] = useState(
        localStorage.getItem("AdminAccessKey")
    );
    const [UserID, setUserID] = useState(localStorage.getItem("AdminID"));

    const [isUpdated, setIsUpdated] = useState(false);
    const [loading, setLoading] = useState(false);
    const [listOfLedger, setLedger] = useState([]);
    const [ledgerOld, setLedgerOld] = useState([]);

    const [loadingBtn, setLoadingBtn] = useState(false);

    const [status, setStatus] = useState("Initiated");
    const [allStatus, setallStatus] = useState("");

    const [statusChangedSuccess, setStatusChangedSuccess] = useState(false);

    const [showFilters, setShowFilters] = useState(true);
    const [Full_Name, setFull_Name] = useState('');
    const [filterStartDate, setfilterStartDate] = useState('');
    const [filterEndDate, setfilterEndDate] = useState('');
    const [GrandTotal, setGrandTotal] = useState('');
    const [paymentMode, setpaymentMode] = useState("All");
    //   //console.log(status);

    // pagination
    const [pageNo, setPageNo] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalRecords, setTotalRecords] = useState(0);
    const [isFilteredByDate, setisFilteredByDate] = useState(false);

    let navigate = useNavigate();

    useEffect(() => {
        document.title = "Initiated Withdrawal List";
        setStatusChangedSuccess(false);
        setIsUpdated(false);
        setLoading(true);

        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
            pageNo: pageNo,
            pageSize: pageSize,
            Status: userstatusVal ? userstatusVal : status,
            UserName: Full_Name,
            startDate: filterStartDate,
            endDate: filterEndDate,
            PaymentMode: paymentMode,
        };

        ////console.log(data);

        var api_config = {
            method: "post",
            url: config.base_url + "/users/GetWithdrawalByStatus",
            headers: {
                "Content-Type": "application/json",
            },
            data: data,
        };

        axios(api_config)
            .then(function (response) {
                //console.log(response.data);
                if (response.data.status_code === 1) {
                    //to remove all null values
                    const filtered = response.data.ListofRecords.map((obj) => {
                        const convertobj = { ...obj };
                        for (let key in convertobj) {
                            if (convertobj[key] === null) {
                                convertobj[key] = "";
                            }
                        }
                        return convertobj;
                    });
                    setLedger(filtered);
                    setLedgerOld(filtered);
                    setGrandTotal(response.data);
                    // setLedger(response.data.ListofRecords);
                    // setLedgerOld(response.data.ListofRecords);
                    setLoading(false);
                    setTotalRecords(response.data.totalRecords);
                    //console.log(totalRecords);
                } else if (
                    (response.data.status_code === 0 &&
                        response.data.status_message === "Invalid Access Key.") ||
                    response.data.status_code === 2
                ) {
                    // navigate("/admin/login");
                } else {

                }
            })
            .catch(function (error) {
                //console.log(error);
            });
    }, [
        pageNo,
        pageSize,
        isUpdated,
        userstatusVal,
        status,
        statusChangedSuccess,
        paymentMode,
        filterStartDate,
        filterEndDate
    ]);

    const handlePageChange = (page) => {
        setPageNo(page);
    };

    // update individual row status ...................
    const UpdateStatus = (e, ID) => {
        const val = e.target.value;
        const confirm = window.confirm("Are you sure you want to proceed ?");
        if (confirm) {
            const updated = listOfLedger.map((row) => {
                if (row.ID === ID) {
                    return { ...row, Status: val };
                } else return row;
            });
            setLedger(updated);

            const data = {
                AccessKey: AccessKey,
                UserID: UserID,
                ID: ID,
            };

            // REJECTED................
            if (val === "Rejected") {
                //console.log(val);
                //console.log("id is", ID);

                var api_config = {
                    method: "post",
                    url: config.base_url + "/Users/RejectWithdrawlRequest",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    data: data,
                };
                //console.log(row);
                // setStatusChangedSuccess(false);
                axios(api_config)
                    .then(function (response) {
                        //console.log("rejected...................", response.data);
                        if (response.data.status_code === 1) {
                            // setStatusById("Rejected")
                            setStatusChangedSuccess(true);

                        } else if (
                            response.data.status_code === 0 &&
                            response.data.status_message === "Failed to cancel the request!"
                        ) {

                        } else if (
                            (response.data.status_code === 0 &&
                                response.data.status_message === "Invalid Access Key.") ||
                            (response.data.status_code === 2 &&
                                response.data.status_message === "Invalid Request Parameters")
                        ) {
                            navigate("/admin/login");
                        }
                    })
                    .catch(function (error) {
                        //console.log(error);
                    });
            }

            // APPROVED................

            if (val === "Approved") {
                //console.log(val);

                var api_config = {
                    method: "post",
                    url: config.base_url + "/users/ApproveWithdrawlRequest",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    data: data,
                };
                //console.log(row);
                // setStatusChangedSuccess(false);
                axios(api_config)
                    .then(function (response) {
                        ////console.log(response.data);
                        if (response.data.status_code === 1) {
                            // setStatusById("Rejected")
                            setStatusChangedSuccess(true);

                        } else if (
                            response.data.status_code === 2 &&
                            response.data.status_message === "Invalid Request Parameters"
                        ) {

                        } else if (
                            response.data.status_code === 0 &&
                            response.data.status_message === "Invalid Access Key."
                        ) {
                            navigate("/admin/login");
                        }
                    })
                    .catch(function (error) {
                        //console.log(error);
                    });
            }

            //TRANSFER..................
            // if (val === "Success") {
            //     //console.log(val);

            //     var api_config = {
            //         method: "post",
            //         url: config.base_url + "/users/SuccessWithdrawlRequest",
            //         headers: {
            //             "Content-Type": "application/json",
            //         },
            //         data: data,
            //     };
            //     //console.log(row);
            //     // setStatusChangedSuccess(false);
            //     axios(api_config)
            //         .then(function (response) {
            //             //console.log("transfered data ", response.data);
            //             if (response.data.status_code === 1) {
            //                 // setStatusById("Rejected")
            //                 setStatusChangedSuccess(true);
            //                 toast.success("Success Successfully !", {
            //                     position: toast.POSITION.TOP_RIGHT,
            //                 });
            //             } else if (
            //                 (response.data.status_code === 0 &&
            //                     response.data.status_message ===
            //                     "Failed to transfer the request!") ||
            //                 (status === "Initiated" && val === "Success")
            //             ) {
            //                 toast.error("Success Failed ! First Approve it to Transfer.", {
            //                     position: toast.POSITION.TOP_RIGHT,
            //                 });
            //             } else if (
            //                 (response.data.status_code === 0 &&
            //                     response.data.status_message === "Invalid Access Key.") ||
            //                 (response.data.status_code === 0 &&
            //                     response.data.status_message === "Invalid Request Parameters")
            //             ) {
            //                 navigate("/admin/login");
            //             }
            //         })
            //         .catch(function (error) {
            //             //console.log(error);
            //         });
            // }
        }
    };
    // update all rows status ...................

    const UpdateAllStatus = (e, ID) => {
        const confirm = window.confirm("Are you sure you want to proceed ?");
        if (confirm) {
            const inputsStudents = document.querySelectorAll("input.userCheck");
            const valuesSelected = [];
            inputsStudents.forEach((input) => {
                if (input.checked == true) {
                    const value = parseInt(input.value);
                    if (value > 0) {
                        valuesSelected.push(value);
                    }
                }
            });
            //console.log([...valuesSelected]);
            // setallStatus( e.target.value);
            let val = e.target.value;
            setallStatus(val);
            //console.log(allStatus);

            const updatedTableData = listOfLedger.map((row, index) => {
                if (valuesSelected.includes(row.ID)) {
                    return {
                        ...row,
                        Status: val,
                    };
                } else {
                    return row;
                }
            });
            setLedger(updatedTableData);
        }
    };
    // filter by date ...................

    const filterByDate = (e) => {
        let date = dayjs(e.target.value).format("MM DD YYYY");

        if (date != "") {
            let member = ledgerOld.filter(
                ////console.log(dayjs(member.Transaction_Date).format('MM DD YYYY, h:mm:ss a'))
                (member) => dayjs(member.Created_Date).format("MM DD YYYY") == date
            );

            setLedger(member);
        } else {
            setLedger(ledgerOld);
        }

        setisFilteredByDate(true);
    };
    // filter by inputs ...................
    const filterAlls = (e) => {
        let term = e.target.value;
        if (term !== "") {
            let filteredArray = listOfLedger.filter((object) =>
                Object.values(object).some((value) =>
                    value.toString().toLowerCase().includes(term.toLowerCase())
                )
            );
            //console.log("filtered ", filteredArray);

            setLedger(filteredArray);
        } else {
            setLedger(ledgerOld);
        }
    };

    // check and uncheck
    const handleCheckAll = (e) => {
        const checkboxes = document.getElementsByClassName("userCheck");

        for (let i = 0; i < checkboxes.length; i++) {
            checkboxes[i].checked = e.target.checked;
        }
    };

    const renderLedger = () => {
        if (listOfLedger != null && listOfLedger.length > 0) {
            let count = 1;
            return listOfLedger.map((item, index) => {
                //console.log(item);
                return (
                    <tr key={index}>
                        <td>
                            <div style={{ textAlign: "center" }}>
                                <input
                                    type="checkbox"
                                    className="form-check-input userCheck"
                                    value={item.ID}
                                />
                            </div>
                        </td>
                        <td>{item.User_Name}</td>
                        <td>{dayjs(item.Created_Date).format("DD/MM/YYYY")}</td>
                        <td>{dayjs(item.DueDate).format("DD/MM/YYYY")}</td>
                        <td>{item.PaymentID}</td>
                        <td>{item.PaymentMode}</td>
                        <td >
                            <div className="d-flex align-items-center justify-content-between">
                                <p className="m-0">
                                    {item.Address.length > 15
                                        ? `${item.Address.substring(0, 15)}...`
                                        : item.Address}
                                </p>
                                <button
                                    className="button "
                                    style={{ marginLeft: "10px" }}
                                    onClick={() => {
                                        navigator.clipboard.writeText(item.Address);

                                    }}
                                >
                                    <span>
                                        <i
                                            style={{ fontSize: "18px" }}
                                            className="material-symbols-outlined"
                                        >
                                            content_copy
                                        </i>
                                    </span>
                                </button>
                            </div>
                        </td>

                        <td>{NumberFormat(item.Amount)}</td>
                        <td>{item.FeePercentage}%</td>
                        <td>{NumberFormat(item.SelectedCurrencyRate)}</td>
                        <td>{NumberFormat(item.NetReturnableInCurrency)}</td>
                        <td>{item.Status}</td>

                        <td>
                            <div className="card card-table ">
                                <div className="card-inner d-flex">
                                    <select
                                        // id="dropdownn"
                                        className="form-select"
                                        style={{ width: "10rem" }}
                                        value={item.Status}
                                        onChange={(e) => UpdateStatus(e, item.ID)}
                                    >
                                        <option selected value="Initiated">Initiated</option>
                                        <option value="Approved"> Approved</option>
                                        <option value="Rejected"> Rejected</option>
                                    </select>
                                </div>
                            </div>
                        </td>
                    </tr>
                );
            });
        } else {
            return (
                <tr>
                    <td colSpan="12">
                        <h4
                            className="mt-3"
                            style={{ fontSize: "15px", color: "rgba(0,0,0,0.6)" }}
                        >
                            No Record Exists
                        </h4>
                    </td>
                </tr>
            );
        }
    };

    // update multiple rows after select multiple rows
    const handleUpdateUsers = (event) => {
        event.preventDefault();
        event.stopPropagation();

        const inputsStudents = document.querySelectorAll("input.userCheck");
        const valuesSelected = [];
        inputsStudents.forEach((input) => {
            if (input.checked == true) {
                const value = parseInt(input.value);
                if (value > 0) {
                    valuesSelected.push(value);
                }
            }
        });
        const commaSeparatedValues = valuesSelected.join(", ");

        //console.log(commaSeparatedValues);

        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
            selectedValues: commaSeparatedValues,
        };

        // REJECTED................
        if (allStatus === "Rejected") {
            setLoadingBtn(true);
            var api_config = {
                method: "post",
                url: config.base_url + "users/RejectAllWithdrawlRequest",
                headers: {
                    "Content-Type": "application/json",
                },
                data: data,
            };
            //console.log(row);
            // setStatusChangedSuccess(false);
            axios(api_config)
                .then(function (response) {
                    ////console.log(response.data);
                    if (response.data.status_code === 1) {
                        // setStatusById("Rejected")
                        setStatusChangedSuccess(true);
                        setLoadingBtn(false);

                    } else if (
                        response.data.status_code === 2 &&
                        response.data.status_message === "Sorry, something went wrong."
                    ) {

                    } else if (
                        (response.data.status_code === 0 &&
                            response.data.status_message === "Invalid Access Key.") ||
                        (response.data.status_code === 2 &&
                            response.data.status_message === "Invalid Request Parameters")
                    ) {
                        navigate("/admin/login");
                    }
                })
                .catch(function (error) {
                    //console.log(error);
                });
        }

        // TRANSFERD................
        if (allStatus === "Success") {
            setLoadingBtn(true);
            var api_config = {
                method: "post",
                url: config.base_url + "users/TransferedAllWithdrawlRequest",
                headers: {
                    "Content-Type": "application/json",
                },
                data: data,
            };
            //console.log(row);
            // setStatusChangedSuccess(false);
            axios(api_config)
                .then(function (response) {
                    ////console.log(response.data);
                    if (response.data.status_code === 1) {
                        // setStatusById("Rejected")
                        setStatusChangedSuccess(true);
                        setLoadingBtn(false);


                    } else if (
                        response.data.status_code === 2 &&
                        response.data.status_message === "Sorry, something went wrong."
                    ) {

                    } else if (
                        (response.data.status_code === 0 &&
                            response.data.status_message === "Invalid Access Key.") ||
                        (response.data.status_code === 2 &&
                            response.data.status_message === "Invalid Request Parameters")
                    ) {
                        navigate("/admin/login");
                    }
                })
                .catch(function (error) {
                    //console.log(error);
                });
        }
    };

    const handleSearch = (event) => {
        event.preventDefault();
        event.stopPropagation();
        setIsUpdated(true);
    };


    return (
        <>
            <AdminRightsCheck />

            <div className="admin-content">


                <div className="row align-items-center">
                    <div className="col-md-6">
                        <div className="d-title m-0">
                            Initiated Withdrawal List
                        </div>
                    </div>
                    <div className="col-md-6 text-end">
                        <div className="">
                            <NavLink to="/admin/withdrawal/dashboard">
                                <button type="button" className="button button-primary">
                                    Back
                                </button>
                            </NavLink>
                            <button
                                onClick={() => {
                                    setShowFilters((prevIsActive) => !prevIsActive);
                                }}
                                className="button button-primary text-white ms-3"
                                style={{ fontWeight: "500", display: "inline-flex", alignItems: "center" }}
                            >
                                {showFilters ? "Hide Filters" : "Show Filters"}
                                <i className="ri-filter-3-line ms-3"></i>
                            </button>
                        </div>
                    </div>
                </div>

                {/* Filters Menu */}
                <div
                    className={`collapse ${showFilters ? "show" : ""} `}
                    id="collapseFilteres"
                >
                    <div className="card card-table mb-3 mt-4">
                        <div className="card-inner">
                            <div className="form_default">
                                <div className="row p-3 gy-3">
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label htmlFor="">Select Payment Mode</label>
                                            <div className="inputwrap">
                                                <select
                                                    id="dropdown"
                                                    className="form-select"
                                                    value={paymentMode}
                                                    onChange={(e) => setpaymentMode(e.target.value)}
                                                >
                                                    <option value="All" selected>All</option>
                                                    <option value="ETH" >ETH</option>
                                                    <option value="USDT"> USDT </option>
                                                    <option value="BTC"> BTC </option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form_default">
                                            <div className="form-group">
                                                <label htmlFor="">From</label>
                                                <div className="inputwrap">
                                                    <input
                                                        type="date"
                                                        className="form-control"
                                                        value={filterStartDate}
                                                        onChange={(e) => setfilterStartDate(e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form_default">
                                            <div className="form-group search_box">
                                                <label htmlFor="">To</label>
                                                <div className="inputwrap">
                                                    <input
                                                        type="date"
                                                        className="form-control"
                                                        value={filterEndDate}
                                                        onChange={(e) => setfilterEndDate(e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-7">
                                        <div className="form_default position-relative">
                                            <Form onSubmit={handleSearch}>
                                                <div className="row align-items-end gy-3 gx-2">
                                                    <div className="col-md-7">
                                                        <div className="form-group search_box">
                                                            <label htmlFor="">Search Member</label>
                                                            <div className="inputwrap">
                                                                <input type="text" className="form-control"
                                                                    placeholder="Find by Username/Member ID/Email"
                                                                    value={Full_Name}
                                                                    onChange={(e) => setFull_Name(e.target.value)}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <button type="submit" className="button w-100 button-primary">
                                                            search
                                                        </button>
                                                    </div>
                                                </div>
                                            </Form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <form onSubmit={handleUpdateUsers}>
                    <div className="card card-table mb-5 mt-3 form_admin">
                        <div className="card-inner">
                            <div className="card overflow-auto">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>select</th>
                                            <th>User Name</th>
                                            <th>Dated</th>
                                            <th>Due Date</th>
                                            <th>Payment ID</th>
                                            <th style={{ minWidth: "140px" }}>Payment Mode</th>
                                            <th>Address</th>
                                            <th>Amount</th>
                                            <th style={{ minWidth: "120px" }}>Admin Fee</th>
                                            <th>Currency Rate</th>
                                            <th>Receiveable</th>
                                            <th>Status</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody className="form_admin">
                                        <tr className="filter-row">
                                            <td>
                                                <div style={{ textAlign: "center" }}>
                                                    <input
                                                        type="checkbox"
                                                        name="allSelect"
                                                        onChange={handleCheckAll}
                                                        className="check"
                                                    />
                                                </div>
                                            </td>
                                            <td>
                                                <div className="inputwrap">
                                                    <input
                                                        type="text"
                                                        placeholder="User Name"
                                                        onChange={(e) => filterAlls(e)}
                                                        className="form-control"
                                                    />
                                                </div>
                                            </td>
                                            <td>
                                                <div className="d-flex">
                                                    <div className="inputwrap">
                                                        <input
                                                            type="date"
                                                            onChange={filterByDate}
                                                            className="form-control"
                                                        />
                                                    </div>
                                                    {isFilteredByDate === true && (
                                                        <button
                                                            className="button p-0 bg-transparent"
                                                            onClick={() => {
                                                                setisFilteredByDate(false);
                                                                setLedger(ledgerOld);
                                                            }}
                                                        >
                                                            <span
                                                                style={{ lineHeight: "unset" }}
                                                                className="material-symbols-outlined"
                                                            >
                                                                close
                                                            </span>
                                                        </button>
                                                    )}
                                                </div>
                                            </td>

                                            <td>
                                                <div className="d-flex">
                                                    <div className="inputwrap">
                                                        <input
                                                            type="date"
                                                            onChange={filterByDate}
                                                            className="form-control"
                                                        />
                                                    </div>
                                                    {isFilteredByDate === true && (
                                                        <button
                                                            className="button p-0 bg-transparent"
                                                            onClick={() => {
                                                                setisFilteredByDate(false);
                                                                setLedger(ledgerOld);
                                                            }}
                                                        >
                                                            <span
                                                                style={{ lineHeight: "unset" }}
                                                                className="material-symbols-outlined"
                                                            >
                                                                close
                                                            </span>
                                                        </button>
                                                    )}
                                                </div>
                                            </td>


                                            <td>
                                                <div className="inputwrap">
                                                    <input
                                                        type="text"
                                                        placeholder="Payment ID"
                                                        onChange={(e) => filterAlls(e)}
                                                        className="form-control"
                                                    />
                                                </div>
                                            </td>
                                            <td>
                                                <div className="inputwrap">
                                                    <input
                                                        type="text"
                                                        placeholder="Payment Mode"
                                                        onChange={(e) => filterAlls(e)}
                                                        className="form-control"
                                                    />
                                                </div>
                                            </td>
                                            <td>
                                                <div className="inputwrap">
                                                    <input
                                                        type="text"
                                                        placeholder="Address"
                                                        onChange={(e) => filterAlls(e)}
                                                        className="form-control"
                                                    />
                                                </div>
                                            </td>
                                            <td>
                                                <div className="inputwrap">
                                                    <input
                                                        type="text"
                                                        placeholder="Amount"
                                                        onChange={(e) => filterAlls(e)}
                                                        className="form-control"
                                                    />
                                                </div>
                                            </td>
                                            <td>
                                                <div className="inputwrap">
                                                    <input
                                                        type="text"
                                                        placeholder="Admin Fee"
                                                        onChange={(e) => filterAlls(e)}
                                                        className="form-control"
                                                    />
                                                </div>
                                            </td>
                                            <td>
                                                <div className="inputwrap">
                                                    <input
                                                        type="text"
                                                        placeholder="Currency Rate"
                                                        onChange={(e) => filterAlls(e)}
                                                        className="form-control"
                                                    />
                                                </div>
                                            </td>
                                            <td>
                                                <div className="inputwrap">
                                                    <input
                                                        type="text"
                                                        placeholder="Receiveable"
                                                        onChange={(e) => filterAlls(e)}
                                                        className="form-control"
                                                    />
                                                </div>
                                            </td>
                                            <td>
                                                <div className="inputwrap">
                                                    <input
                                                        type="text"
                                                        placeholder="Status"
                                                        onChange={(e) => filterAlls(e)}
                                                        className="form-control"
                                                    />
                                                </div>
                                            </td>
                                            <td>
                                                <div className="card card-table "
                                                    style={{ width: "11rem" }}>
                                                    <div className="card-inner d-flex"
                                                    >
                                                        <select
                                                            className="form-select"
                                                            value={allStatus}
                                                            onChange={(e) => UpdateAllStatus(e)}
                                                        >
                                                            <option selected value="Initiated">Initiated</option>
                                                            <option value="Approved"> Approved</option>
                                                            <option value="Rejected"> Rejected</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                        {loading ? (
                                            <tr>
                                                <td className="text-center" colSpan="12">
                                                    <Spinner animation="border" role="status"></Spinner>
                                                </td>
                                            </tr>
                                        ) : (
                                            <>{renderLedger()}

                                                {listOfLedger != null && listOfLedger.length > 0 &&
                                                    <>
                                                        <tr style={{ borderButtom: "none !important" }}>
                                                            <td className="text-end border-bottom-0 pt-3" colSpan={7}>Subtotal:</td>
                                                            <td className="border-bottom-0 pt-3">{NumberFormat(calculateColumnTotal(listOfLedger, 'Amount'))}</td>
                                                            <td className="border-bottom-0 pt-3" colSpan={5}></td>
                                                        </tr>
                                                        <tr className="">
                                                            <td className="text-end border-bottom-0" colSpan={7}>Grand Total: </td>
                                                            <td className="border-bottom-0">{NumberFormat(GrandTotal.totalAmount)}</td>
                                                            <td className="border-bottom-0 pt-3" colSpan={5}></td>
                                                        </tr>
                                                    </>
                                                }


                                            </>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                    {loadingBtn ? (
                        <div className="text-end">
                            <Spinner animation="border" role="status"></Spinner>
                        </div>
                    ) : (
                        <div className="text-end mb-3">
                            <button type="submit" className="button button-primary">
                                Update
                            </button>
                        </div>
                    )}
                </form>

                {totalRecords > 10 && (
                    <div className="pagination-wrap">
                        <div className="row align-items-center">
                            <div className="col-md-2 form_default">
                                <div className="d-flex align-items-center">
                                    <label className="me-2" style={{ fontSize: "15px" }}>
                                        Page size
                                    </label>
                                    <div className="form-group">
                                        <select
                                            className="form-select"
                                            style={{ width: "100px" }}
                                            onChange={(e) => {
                                                setPageNo(1);
                                                setPageSize(parseInt(e.target.value));
                                            }}
                                        >
                                            <option value="10" selected>
                                                10
                                            </option>
                                            <option value="20">20</option>
                                            <option value="50">50</option>
                                            <option value="100">100</option>
                                            <option value="500">500</option>
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <Pagination
                                pageNo={pageNo}
                                pageSize={pageSize}
                                totalRecords={totalRecords}
                                onPageChange={handlePageChange}
                            />
                        </div>
                    </div>
                )}
            </div>
        </>
    );
}

export default Initiated;

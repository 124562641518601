import React, { useState, useEffect } from "react";
import PageTitle from "../PageTitle";
import AdminCard from "../AdminCard";
import cash from "../../../assets/images/admin/icons/cash.png";
import paid from "../../../assets/images/admin/icons/paid.png";
import recovered_balance from "../../../assets/images/admin/icons/recovered_balance.png";
import reversal from "../../../assets/images/admin/icons/reversal.png";
import AdminRightsCheck from "../AdminRightsCheck";


const AdminFeeDashboard = () => {
  return (
    <>
      <AdminRightsCheck />

      <PageTitle title="ADMIN FEE" />
      <div className="admin-content">
        <div className="row">
          <div className="col-md-3">
            <AdminCard
              link="#/"
              class="green"
              value="50$"
              label="ADMIN FEE
"
              icon={cash}
              progress="progress"
              progressbar="progress-bar"
              progressrole="progressbar"
              val="10%"
              arianow="10"
              ariamin="0"
              ariamax="100"
              progressval=""
            />
          </div>
          <div className="col-md-3">
            <AdminCard
              link="#/"
              class="brown"
              value="20$"
              label="TOTAL
DONATION"
              icon={paid}
              progress="progress"
              progressbar="progress-bar"
              progressrole="progressbar"
              val="50%"
              arianow="10"
              ariamin="0"
              ariamax="100"
              progressval=""
            />
          </div>
          <div className="col-md-3">
            <AdminCard
              link="#/"
              class="blue"
              value="15$"
              label="TOTAL REVERSAL
FEE"
              icon={recovered_balance}
              progress="progress"
              progressbar="progress-bar"
              progressrole="progressbar"
              val="3%"
              arianow="10"
              ariamin="0"
              ariamax="100"
              progressval=""
            />
          </div>
          <div className="col-md-3">
            <AdminCard
              link="#/"
              class="red"
              value="12$"
              label="TOTAL REVERSAL
WITHDRAWAL"
              icon={reversal}
              progress="progress"
              progressbar="progress-bar"
              progressrole="progressbar"
              val="10%"
              arianow="10"
              ariamin="0"
              ariamax="100"
              progressval=""
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default AdminFeeDashboard;

import React, { useState, useEffect } from "react";
import config from "../../../Config";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import { useNavigate } from "react-router-dom";
import { NavLink, Link } from "react-router-dom";
   
import dayjs from "dayjs";
import Pagination from "../../member/General/Pagination";

import Dropdown from "react-bootstrap/Dropdown";
import AdminRightsCheck from "../AdminRightsCheck";

function ReferralBonusList(props) {
  // const [AccessKey, setAccessKey] = useState(localStorage.getItem("AdminAccessKey"));
  // const [UserID, setUserID] = useState(localStorage.getItem("AdminID"));

  // const [isUpdated, setIsUpdated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [listOfLedger, setLedger] = useState([]);

  // pagination
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);

  // useEffect(() => {

  //     document.title = "KYC REQUESTS List";

  //     setIsUpdated(false);
  //     setLoading(true);

  //     const data = {
  //         AccessKey: AccessKey,
  //         UserID: UserID,
  //         pageNo: pageNo,
  //         pageSize: pageSize,
  //     };

  //     //console.log(data);

  //     var api_config = {
  //         method: 'post',
  //         url: config.base_url + 'Users/GetKYCList',
  //         headers: {
  //             'Content-Type': 'application/json'
  //         },
  //         data: data
  //     };

  //     axios(api_config)
  //         .then(function (response) {
  //             if (response.data.status_code === 1) {
  //             //console.log("data", response.data);
  //             setLedger(response.data.ListofLedger);
  //             setLoading(false);
  //             setTotalRecords(response.data.totalRecords);
  //             //console.log(totalRecords);

  //             }
  //         })
  //         .catch(function (error) {
  //             //console.log(error);
  //         });

  // }, [pageNo, pageSize, isUpdated]);

  const handlePageChange = (page) => {
    setPageNo(page);
  };

  const renderLedger = () => {
    // if (listOfLedger != null && listOfLedger.length > 0) {
    let count = 1;
    return listOfLedger.map((item, index) => {
      //console.log(item);
      return (
        <tr key={index}>
          <td>{count++ + pageSize * (pageNo - 1)}</td>
        </tr>
      );
    });
    // }
    // else {
    // return (
    //     <tr>
    //         <td colSpan="9"><h4 className="mt-3" style={{ fontSize: "15px", color: "rgba(0,0,0,0.6)" }}>No Record Exists</h4></td>
    //     </tr>
    // )
    // }
  };
  return (
    <>
      <AdminRightsCheck />
      {/* <div className="container">
        <div className="card card-table mb-5 mt-3 ">
          <div className="card-inner d-flex">
            <h6 className="my-auto mx-4"> Select Status</h6>
            <select
              id="dropdown"
              className="form-select"
              style={{ width: "10rem" }}
            //   value={userstatusVal ? userstatusVal : status}
            //   onChange={(e) => setStatus(e.target.value)}
            >
              <option value="" selected>
              Pending 
              </option>
              <option value="Active"> Approved </option>
              <option value="Inactive"> Rejected </option>
              
            </select>
          </div>
        </div>
      </div> */}

      <div className="admin-content">
        <div className="row align-items-center">
          <div className="col-md-6">
            <div className="d-title m-0">
              REFERRAL BONUS
            </div>
          </div>
          <div className="col-md-6 text-end">
            <div className="">
              <NavLink to="/admin/bonus-panel/dashboard">
                <button type="button" className="button button-primary">
                  Back
                </button>
              </NavLink>
              {/* <button
                  onClick={() => {
                    setShowFilters((prevIsActive) => !prevIsActive);
                  }}
                  className="button button-primary text-white ms-3"
                  style={{ fontWeight: "500", display: "inline-flex", alignItems: "center" }}
                >
                  {showFilters ? "Hide Filters" : "Show Filters"}
                  <i className="ri-filter-3-line ms-3"></i>
                </button> */}
            </div>
          </div>
        </div>

        {/* Filters Menu */}
        {/* <div
            className={`collapse ${showFilters ? "show" : ""} `}
            id="collapseFilteres"
          >
            <div className="card card-table mb-3 mt-4">
              <div className="card-inner">
                <div className="form_default">
                  <div className="row p-3">
                    <div className="col-md-3">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}

        <div className="card card-table mb-5 mt-3">
          <div className="card-inner">
            <table className="table">
              <thead>
                <tr>
                  <th>Sr. No</th>
                  <th>Date</th>
                  <th>Self User Id</th>
                  <th>Referrer User Id </th>
                  <th>Level </th>
                  <th>Position  </th>
                  <th>Total Package </th>
                  <th>Percentage </th>
                  <th>Total Received</th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <tr>
                    <td className="text-center" colSpan="9">
                      <Spinner animation="border" role="status"></Spinner>
                    </td>
                  </tr>
                ) : (
                  <>{renderLedger()}</>
                )}
              </tbody>
            </table>
          </div>
        </div>
        {totalRecords > 10 && (
          <div className="pagination-wrap">
            <div className="row align-items-center">
              <div className="col-md-2 form_default">
                <div className="d-flex align-items-center">
                  <label className="me-2" style={{ fontSize: "15px" }}>
                    Page size
                  </label>
                  <div className="form-group">
                    <select
                      className="form-select"
                      style={{ width: "100px" }}
                      onChange={(e) => {
                        setPageNo(1);
                        setPageSize(parseInt(e.target.value));
                      }}
                    >
                      <option value="10" selected>
                        10
                      </option>
                      <option value="20">20</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                      <option value="500">500</option>
                    </select>
                  </div>
                </div>
              </div>

              <Pagination
                pageNo={pageNo}
                pageSize={pageSize}
                totalRecords={totalRecords}
                onPageChange={handlePageChange}
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default ReferralBonusList;

import React, { useState, useEffect } from "react";

import axios from "axios";
import { useNavigate } from 'react-router-dom';
import config from "../../Config";

function AdminRightsCheck(props) {

    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AdminAccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("AdminID"));
    const [MemberType, setMemberType] = useState(localStorage.getItem("MemberType"));

    let navigate = useNavigate();
    useEffect(() => {

        if (AccessKey == null) {
            navigate("/admin/login");
        }
        else {
            const data = {
                AccessKey: AccessKey,
                UserID: UserID,
                MemberType: MemberType,
                URL: window.location.pathname,
            };

            //console.log(data);

            var api_config = {
                method: 'post',
                url: config.base_url + 'Security/ValidateAccessKey',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };

            axios(api_config)
                .then(function (response) {
                    // console.log(response.data);
                    if (response.data.status_code == 1 && response.data.hasRights == false) {
                        localStorage.clear();
                        navigate("/admin/no-access");
                    }
                    if (response.data.hasRights == false) {
                        localStorage.clear();
                        navigate("/admin/login");
                    }
                    if (response.data.status_message != "Access Key is Valid.") {
                        localStorage.clear();
                        navigate("/admin/login");
                    }

                })
                .catch(function (error) {
                    //console.log(error);
                });
        }
    }, []);

    return (
        <>

        </>
    );
}

export default AdminRightsCheck;
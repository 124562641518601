import React, { useState, useEffect } from "react";
import config from "../../../Config";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import { useNavigate } from "react-router-dom";
import { NavLink, Link } from "react-router-dom";
import dayjs from "dayjs";
import Pagination from "../../member/General/Pagination";

// import LedgerMenu from "../../member/Ledger/LedgerMenu";
import AdminRightsCheck from "../AdminRightsCheck";
import { Form } from "react-bootstrap";
import { calculateColumnTotal } from "../../Common/Util";
import { NumberFormat } from "../../member/General/Functions";
function Succeeded(props) {
  const [AccessKey, setAccessKey] = useState(
    localStorage.getItem("AdminAccessKey")
  );
  const [UserID, setUserID] = useState(localStorage.getItem("AdminID"));

  const [isUpdated, setIsUpdated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [listOfLedger, setLedger] = useState([]);
  const [ledgerOld, setLedgerOld] = useState([]);

  const [loadingBtn, setLoadingBtn] = useState(false);

  const [status, setStatus] = useState("Success");
  const [allStatus, setallStatus] = useState("");

  const [showFilters, setShowFilters] = useState(true);

  const [statusChangedSuccess, setStatusChangedSuccess] = useState(false);

  //   //console.log(status);

  // pagination
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [isFilteredByDate, setisFilteredByDate] = useState(false);
  const [Full_Name, setFull_Name] = useState('');
  const [paymentModee, setPaymentModee] = useState("")
  const [Records, setRecords] = useState([])
  const [NewTotalRecords, setNewTotalRecords] = useState(0)

  const [filterStartDate, setfilterStartDate] = useState('');
  const [filterEndDate, setfilterEndDate] = useState('');
  const [GrandTotal, setGrandTotal] = useState('');
  const [paymentMode, setpaymentMode] = useState("All");

  let navigate = useNavigate();

  useEffect(() => {
    document.title = "Succeeded Withdrawal List";
    setStatusChangedSuccess(false);
    setIsUpdated(false);
    setLoading(true);

    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      pageNo: pageNo,
      pageSize: pageSize,
      Status: status,
      UserName: Full_Name,
      startDate: filterStartDate,
      endDate: filterEndDate,
      PaymentMode: paymentMode,
    };

    ////console.log(data);

    var api_config = {
      method: "post",
      url: config.base_url + "/users/GetWithdrawalByStatus",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        //console.log("check ot", response.data);
        if (response.data.status_code === 1) {
          //to remove all null values
          const filtered = response.data.ListofRecords.map((obj) => {
            const convertobj = { ...obj };
            for (let key in convertobj) {
              if (convertobj[key] === null) {
                convertobj[key] = "";
              }
            }
            return convertobj;
          });
          setLedger(filtered);
          setRecords(filtered)

          setLedgerOld(filtered);
          // setLedger(response.data.ListofRecords);
          setGrandTotal(response.data);
          setLoading(false);
          setTotalRecords(response.data.totalRecords);
          //console.log(totalRecords);
        } else if (
          (response.data.status_code === 0 &&
            response.data.status_message === "Invalid Access Key.") ||
          response.data.status_code === 2
        ) {
          navigate("/admin/login");
        } else {

        }
      })
      .catch(function (error) {
        //console.log(error);
      });
  }, [pageNo, pageSize, isUpdated, status,paymentMode, statusChangedSuccess, filterEndDate, filterStartDate]);

  const handlePageChange = (page) => {
    setPageNo(page);
  };



  // filter by date ...................

  const filterByDate = (e) => {
    let date = dayjs(e.target.value).format("MM DD YYYY");

    if (date != "") {
      let member = ledgerOld.filter(
        ////console.log(dayjs(member.Transaction_Date).format('MM DD YYYY, h:mm:ss a'))
        (member) => dayjs(member.Created_Date).format("MM DD YYYY") == date
      );

      setLedger(member);
    } else {
      setLedger(ledgerOld);
    }

    setisFilteredByDate(true);
  };
  // filter by inputs ...................
  const filterAlls = (e) => {
    let term = e.target.value;
    if (term !== "") {
      let filteredArray = listOfLedger.filter((object) =>
        Object.values(object).some((value) =>
          value.toString().toLowerCase().includes(term.toLowerCase())
        )
      );
      //console.log("filtered ", filteredArray);

      setLedger(filteredArray);
    } else {
      setLedger(ledgerOld);
    }
  };
  const FilteredData = (e) => {
    //  //console.log(listOfLedger);
    let value = e.target.value;

    if (value == "All") {
      setRecords(ledgerOld);
    }
    else {
      const filterRecord = setRecords(listOfLedger.filter(item => item.PaymentMode === value));
      //console.log(filterRecord.length);

    }


  }


  const renderLedger = () => {
    if (listOfLedger != null && listOfLedger.length > 0) {
      let count = 1;
      return Records.map((item, index) => {
        //console.log(item);
        return (
          <tr key={index}>
            <td>
              {item.User_Name.length > 10
                ? `${item.User_Name.substring(0, 10)}...`
                : item.User_Name}
            </td>
            <td>{dayjs(item.Created_Date).format("DD/MM/YYYY")}</td>
            <td>
              {item.PaymentID.length > 10
                ? `${item.PaymentID.substring(0, 10)}...`
                : item.PaymentID}
            </td>
            <td>{item.PaymentMode}</td>
            <td className="">
              <div className="d-flex align-items-center justify-content-between">
                <p className="m-0">
                  {item.Address.length > 15
                    ? `${item.Address.substring(0, 15)}...`
                    : item.Address}
                </p>
                <button
                  className="button "
                  style={{ marginLeft: "10px", textAlign: "left" }}
                  onClick={() => {
                    navigator.clipboard.writeText(item.Address);

                  }}
                >
                  <span>
                    <i
                      style={{ fontSize: "18px" }}
                      className="material-symbols-outlined"
                    >
                      content_copy
                    </i>
                  </span>
                </button>
              </div>
            </td>
            <td>{NumberFormat(item.Amount)}</td>
            <td>{NumberFormat(item.SelectedCurrencyRate)}</td>
            <td>{NumberFormat(item.NetReturnableInCurrency)}</td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td colSpan="10">
            <h4
              className="mt-3"
              style={{ fontSize: "15px", color: "white" }}
            >
              No Record Exists
            </h4>
          </td>
        </tr>
      );
    }
  };


  const handleSearch = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsUpdated(true);
  };


  return (
    <>
      <AdminRightsCheck />

      <div className="admin-content">

        <div className="row align-items-center">
          <div className="col-md-6">
            <div className="d-title m-0">
              Succeeded Withdrawal List
            </div>
          </div>
          <div className="col-md-6 text-end">
            <div className="">
              <NavLink to="/admin/withdrawal/dashboard">
                <button type="button" className="button button-primary">
                  Back
                </button>
              </NavLink>
              <button
                onClick={() => {
                  setShowFilters((prevIsActive) => !prevIsActive);
                }}
                className="button button-primary ms-3"
                style={{ fontWeight: "500", display: "inline-flex", alignItems: "center" }}
              >
                {showFilters ? "Hide Filters" : "Show Filters"}
                <i className="ri-filter-3-line ms-3"></i>
              </button>
            </div>
          </div>
        </div>

        <div
          className={`collapse ${showFilters ? "show" : ""} `}
          id="collapseFilteres"
        >
          <div className="card card-table mb-3 mt-4">
            <div className="card-inner">
              <div className="form_default">
                <div className="row p-3 gy-3">
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="">Select Payment Mode</label>
                      <div className="inputwrap">
                        <select
                          id="dropdown"
                          className="form-select"
                          value={paymentMode}
                          onChange={(e) => setpaymentMode(e.target.value)}
                        >
                          <option value="All" selected>All</option>
                          <option value="ETH" >ETH</option>
                          <option value="USDT"> USDT </option>
                          <option value="BTC"> BTC </option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form_default">
                      <div className="form-group">
                        <label htmlFor="">From</label>
                        <div className="inputwrap">
                          <input
                            type="date"
                            className="form-control"
                            value={filterStartDate}
                            onChange={(e) => setfilterStartDate(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form_default">
                      <div className="form-group search_box">
                        <label htmlFor="">To</label>
                        <div className="inputwrap">
                          <input
                            type="date"
                            className="form-control"
                            value={filterEndDate}
                            onChange={(e) => setfilterEndDate(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-7">
                    <div className="form_default position-relative">
                      <Form onSubmit={handleSearch}>
                        <div className="row align-items-end gy-3 gx-2">
                          <div className="col-md-7">
                            <div className="form-group search_box">
                              <label htmlFor="">Search Member</label>
                              <div className="inputwrap">
                                <input type="text" className="form-control"
                                  placeholder="Find by Username/Member ID/Email"
                                  value={Full_Name}
                                  onChange={(e) => setFull_Name(e.target.value)}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-3">
                            <button type="submit" className="button w-100 button-primary">
                              search
                            </button>
                          </div>
                        </div>
                      </Form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div className="card card-table mb-5 mt-3">
          <div className="card-inner">
            <div className="card overflow-auto">
              <table className="table">
                <thead>
                  <tr>
                    <th>User Name</th>
                    <th>Dated</th>
                    <th>Payment ID</th>
                    <th>Payment Mode</th>
                    <th>Address</th>
                    <th>Amount</th>
                    <th>Currency Rate</th>
                    <th>Receiveable</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="filter-row">
                    <td>
                      <div className="inputwrap">
                        <input
                          type="text"
                          style={{ width: "120px" }}
                          placeholder="User Name"
                          onChange={(e) => filterAlls(e)}
                          className="form-control"
                        />
                      </div>
                    </td>

                    <td>
                      <div className="d-flex">
                        <div className="inputwrap">
                          <input
                            type="date"
                            style={{ width: "180px" }}
                            onChange={filterByDate}
                            className="form-control"
                          />
                        </div>
                        {isFilteredByDate === true && (
                          <button
                            type="button"
                            className="button p-0 bg-transparent"
                            onClick={() => {
                              setisFilteredByDate(false);
                              setLedger(ledgerOld);
                            }}
                          >
                            <span
                              style={{ lineHeight: "unset" }}
                              className="material-symbols-outlined"
                            >
                              close
                            </span>
                          </button>
                        )}
                      </div>
                    </td>

                    <td>
                      <div className="inputwrap">
                        <input
                          style={{ width: "150px" }}
                          type="text"
                          placeholder="Payment ID"
                          onChange={(e) => filterAlls(e)}
                          className="form-control"
                        />
                      </div>
                    </td>
                    <td>
                      <div className="inputwrap"
                        style={{ minWidth: "132px" }}>
                        <input
                          type="text"
                          style={{ width: "150px" }}
                          placeholder="Payment Mode"
                          onChange={(e) => filterAlls(e)}
                          className="form-control"
                        />
                      </div>
                    </td>
                    <td>
                      <div className="inputwrap">
                        <input
                          type="text"
                          placeholder="Address"
                          onChange={(e) => filterAlls(e)}
                          className="form-control"
                        />
                      </div>
                    </td>
                    <td>
                      <div className="inputwrap">
                        <input
                          type="text"
                          placeholder="Amount"
                          onChange={(e) => filterAlls(e)}
                          className="form-control"
                        />
                      </div>
                    </td>

                    <td>
                      <div className="inputwrap"
                        style={{ minWidth: "122px" }}>
                        <input
                          type="text"
                          placeholder="Currency Rate"
                          onChange={(e) => filterAlls(e)}
                          className="form-control"
                        />
                      </div>
                    </td>
                    <td>
                      <div className="inputwrap">
                        <input
                          type="text"
                          placeholder="Receiveable"
                          onChange={(e) => filterAlls(e)}
                          className="form-control"
                        />
                      </div>
                    </td>



                  </tr>

                  {loading ? (
                    <tr>
                      <td className="text-center" colSpan="10">
                        <Spinner animation="border" role="status"></Spinner>
                      </td>
                    </tr>
                  ) : (
                    <>{renderLedger()}

                      {listOfLedger != null && listOfLedger.length > 0 &&

                        <>
                          <tr style={{ borderButtom: "none !important" }}>
                            <td className="text-end border-bottom-0 pt-3" colSpan={5}>Subtotal:</td>
                            <td className="border-bottom-0 pt-3">{NumberFormat(calculateColumnTotal(listOfLedger, 'Amount'))}</td>
                            <td className="border-bottom-0 pt-3"></td>
                            <td className="border-bottom-0 pt-3"></td>
                          </tr>
                          <tr className="">
                            <td className="text-end border-bottom-0" colSpan={5} >Grand Total: </td>
                            <td className="border-bottom-0">{NumberFormat(GrandTotal.totalAmount)}</td>
                            <td className="border-bottom-0"></td>
                            <td className="border-bottom-0"></td>
                          </tr>
                        </>

                      }
                    </>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>



        {totalRecords > 10 && (
          <div className="pagination-wrap">
            <div className="row align-items-center">
              <div className="col-md-2 form_default">
                <div className="d-flex align-items-center">
                  <label className="me-2" style={{ fontSize: "15px" }}>
                    Page size
                  </label>
                  <div className="form-group">
                    <select
                      className="form-select"
                      style={{ width: "100px" }}
                      onChange={(e) => {
                        setPageNo(1);
                        setPageSize(parseInt(e.target.value));
                      }}
                    >
                      <option value="10" selected>
                        10
                      </option>
                      <option value="20">20</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                      <option value="500">500</option>
                      <option value="1000">1000</option>
                    </select>
                  </div>
                </div>
              </div>

              <Pagination
                pageNo={pageNo}
                pageSize={pageSize}
                totalRecords={totalRecords}
                onPageChange={handlePageChange}
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default Succeeded;

import React, { useState, useEffect } from "react";

import { Form } from "react-bootstrap";
import config from "../../Config";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { generateRandomString } from "../member/General/Functions";
import ReCAPTCHA from 'react-google-recaptcha';

function AdminLogin(props) {
  const [AccessKey, setAccessKey] = useState(
    localStorage.getItem("AdminAccessKey")
  );
  const [UserID, setUserID] = useState(localStorage.getItem("AdminID"));
  const [captcha, setCaptcha] = useState("");
  const [Usercaptcha, setUsercaptcha] = useState("");
  const [validated, setValidated] = useState(false);
  const [loading, setLoading] = useState(false);


  const [captchaValue, setCaptchaValue] = useState(null);

  const handleCaptchaChange = (value) => {
    setCaptchaValue(value);
  }

  let navigate = useNavigate();

  useEffect(() => {
    setCaptcha(generateRandomString(6));

  }, [])
  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {

      // check captcha
      if (captchaValue) {

        setLoading(true);
        const signInData = {
          Email_Address: event.target.email.value,
          Password: event.target.password.value,
        };

        var api_config = {
          method: "post",
          url: config.base_url + "Users/SignIn",
          headers: {
            "Content-Type": "application/json",
          },
          data: signInData,
        };
        //  //console.log(api_config);
        axios(api_config)
          .then(function (response) {
            //  //console.log(response.data);
            if (response.data.status_code == 1) {
              localStorage.setItem("AdminAccessKey", response.data.AccessKey);
              localStorage.setItem("AdminID", response.data.ID);
              localStorage.setItem("AdminFull_Name", response.data.Full_Name);
              localStorage.setItem("MemberType", "Admin");

              navigate("/admin/dashboard");
              toast.success(response.data.status_message, {
                position: "top-right"
              });

            } else {
              toast.error(response.data.status_message, {
                position: "top-right"
              });
            }
            setLoading(false);
          })
          .catch(function (error) {
            setLoading(false);

          });
      } else {
        alert("Please complete the CAPTCHA");
      }
    }
    setValidated(true);
  };
  const UpdateCaptcha = () => {
    setCaptcha(generateRandomString(6));
  }


  return (
    <>
      <ToastContainer />

      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <div className="admin_login_wrap">
          <div className="login_box">
            <div className="login_box_head">
              {/* <img src={Logo} /> */}
              <h4>Let's Get Started</h4>
              <p>Sign in to continue to Business Intelligence.</p>
            </div>
            <div className="login_box_body form_default">
              <div className="form-group mb-3">
                <label>Email</label>
                <input
                  type="email"
                  name="email"
                  style={{ color: "#FFF" }}
                  className="form-control"
                  required
                />
              </div>
              <div className="form-group mb-4">
                <label>Password</label>
                <input
                  type="password"
                  name="password"
                  style={{ color: "#FFF" }}
                  className="form-control"
                  required
                />
              </div>
              {/* <div className="verify mb-4">
                <div className="Character" >
                  {captcha}
                </div>
                <div className="form-group">
                  <input type="text" onChange={(e) => setUsercaptcha(e.target.value)} style={{ color: "#FFF" }} className="form-control" name="" placeholder="Enter Captcha" required />
                </div>
              </div> */}
              <div className="mb-4">
                <ReCAPTCHA
                  theme="dark"
                  sitekey="6LcwaiIqAAAAAAdNMVGE2w67u8s_yH4rzxAe3YP4"
                  onChange={handleCaptchaChange}
                />
              </div>
              {loading ? (
                <div className="text-center">
                  <Spinner animation="border" role="status"></Spinner>
                </div>
              ) : (
                <button type="submit" className="button button-primary w-100">
                  Login
                </button>
              )}
            </div>
          </div>
        </div>
      </Form>

    </>
  );
}

export default AdminLogin;

import React, { useState, useEffect } from "react";
import PageTitle from "../PageTitle";
import AdminCard from "../AdminCard";


import CRYPTO from "../../../assets/images/admin/icons/CRYPTO.png";
import pendings from "../../../assets/images/admin/icons/pendings.png";

import AdminRightsCheck from "../AdminRightsCheck";
import Spinner from "react-bootstrap/Spinner";

function TotalTransferDashboard() {
  const [loading, setLoading] = useState(false);



  return (
    <>
      <AdminRightsCheck />
      {loading ? (
        <div
          className="d-flex justify-content-center"
          style={{ marginTop: "20%" }}
        >
          <Spinner animation="border" role="status"></Spinner>
        </div>
      ) : (
        <>

          <PageTitle title="TOTAL TRANSFERS" />
          <div className="admin-content">
            <div className="row">

              <div className="col-md-3">
                <AdminCard
                  link="/admin/total-transfer/admin-transfers"
                  class="blue"
                  // value={NumberFormat(depositStatics.totalDeposit)}
                  label="Admin Transfers"
                  icon={pendings}
                  progress="progress"
                  progressbar="progress-bar"
                  progressrole="progressbar"
                  val="10%"
                  arianow="10"
                  ariamin="0"
                  ariamax="100"
                  progressval=""
                />
              </div>
              <div className="col-md-3">
                <AdminCard
                  link="/admin/total-transfer/mutual-transfers"
                  class="green"
                  // value={NumberFormat(depositStatics.cryptoDeposit)}
                  label="Mutual Transfers"
                  icon={CRYPTO}
                  progress="progress"
                  progressbar="progress-bar"
                  progressrole="progressbar"
                  val="16%"
                  arianow="10"
                  ariamin="0"
                  ariamax="100"
                  progressval=""
                />
              </div>

              <div className="col-md-3">
                <AdminCard
                  link="/admin/total-transfer/autocollectfee"
                  class="blue"
                  // value={NumberFormat(depositStatics.totalDeposit)}
                  label="Auto Collect Fee"
                  icon={pendings}
                  progress="progress"
                  progressbar="progress-bar"
                  progressrole="progressbar"
                  val="10%"
                  arianow="10"
                  ariamin="0"
                  ariamax="100"
                  progressval=""
                />
              </div>


            </div>
          </div>
        </>
      )}
    </>

  );
}

export default TotalTransferDashboard;

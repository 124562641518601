import React, { useState, useEffect } from "react";
import axios from "axios";
import config from "../../../Config";
import PageTitle from "../PageTitle";
import AdminCard from "../AdminCard";

import bonus from "../../../assets/images/admin/icons/bonus.png";
import pkges from "../../../assets/images/admin/icons/pkges.png";
import roi from "../../../assets/images/admin/icons/roi.png";
import cash_deposit from "../../../assets/images/admin/icons/cash_deposit.png";

import fundation from "../../../assets/images/admin/icons/fundation.png";
import total_transfer from "../../../assets/images/admin/icons/total_transfer.png";

import AdminHeader from "../AdminHeader";

   
   
import AdminRightsCheck from "../AdminRightsCheck";

function CommodityPannelDashboard() {
  return (
    <>
    <AdminRightsCheck />
         
      <PageTitle title="COMMODITY PANEL" />
      <div className="admin-content">
        <div className="row">
          <div className="col-md-3">
            <AdminCard
              link="/admin/MembersList"
              class="green"
              value="50$"
              label="TOTAL BONUS
              RECEIVED
              "
              icon={bonus}
              progress="progress"
              progressbar="progress-bar"
              progressrole="progressbar"
              val="10%"
              arianow="10"
              ariamin="0"
              ariamax="100"
              progressval=""
            />
          </div>
          <div className="col-md-3">
            <AdminCard
              link="#/"
              class="green"
              value="12"
              label="TOTAL PAID
              BONUS
"
              icon={bonus}
              progress="progress"
              progressbar="progress-bar"
              progressrole="progressbar"
              val="10%"
              arianow="10"
              ariamin="0"
              ariamax="100"
              progressval=""
            />
          </div>
          <div className="col-md-3">
            <AdminCard
              link="#/"
              class="green"
              value="50$"
              label="TOTAL ROI PAID
              "
              icon={roi}
              progress="progress"
              progressbar="progress-bar"
              progressrole="progressbar"
              val="10%"
              arianow="10"
              ariamin="0"
              ariamax="100"
              progressval=""
            />
          </div>

          <div className="col-md-3">
            <AdminCard
              link="#/"
              class="green"
              value="10$"
              label="TOTAL PACKAGE"
              icon={pkges}
              progress="progress"
              progressbar="progress-bar"
              progressrole="progressbar"
              val="10%"
              arianow="100"
              ariamin="0"
              ariamax="100"
              progressval=""
            />
          </div>
          <div className="col-md-3">
            <AdminCard
              link="#/"
              class="brown"
              value="14$"
              label="TOTAL
              FOUNDATION"
              icon={fundation}
              progress="progress"
              progressbar="progress-bar"
              progressrole="progressbar"
              val="10%"
              arianow="50"
              ariamin="0"
              ariamax="100"
              progressval=""
            />
          </div>



          <div className="col-md-3">
            <AdminCard
              link="#/"
              class="brown"
              value="4$"
              label="TOTAL PACAKGE
              WITHDRAW"
              icon={cash_deposit}
              progress="progress"
              progressbar="progress-bar"
              progressrole="progressbar"
              val="10%"
              arianow="10"
              ariamin="0"
              ariamax="100"
              progressval=""
            />
          </div>

          

          <div className="col-md-3">
            <AdminCard
              link="#/"
              class="red"
              value="41$"
              label="TOTAL PACKAGE
              REPURCHASE"
              
              icon={total_transfer}
              progress="progress"
              progressbar="progress-bar"
              progressrole="progressbar"
              val="40%"
              arianow="10"
              ariamin="0"
              ariamax="100"
              progressval=""
            />
          </div>


          
          <div className="col-md-3">
            <AdminCard
              link="/admin/commodity-panel/direct-bonus"
              class="green"
              value="41$"
              label="DIRECT BONUS"
              
              icon={bonus}
              progress="progress"
              progressbar="progress-bar"
              progressrole="progressbar"
              val="70%"
              arianow="10"
              ariamin="0"
              ariamax="100"
              progressval=""
            />
          </div>


          
          <div className="col-md-3">
            <AdminCard
              link="/admin/commodity-panel/investor-bonus"
              class="blue"
              value="41$"
              label="INVESTOR BONUS"
              
              icon={bonus}
              progress="progress"
              progressbar="progress-bar"
              progressrole="progressbar"
              val="40%"
              arianow="10"
              ariamin="0"
              ariamax="100"
              progressval=""
            />
          </div>


          
          <div className="col-md-3">
            <AdminCard
              link="/admin/commodity-panel/repurchase-bonus"
              class="green"
              value="41$"
              label="REPURCHASE
              BONUS"
              
              icon={bonus}
              progress="progress"
              progressbar="progress-bar"
              progressrole="progressbar"
              val="10%"
              arianow="10"
              ariamin="0"
              ariamax="100"
              progressval=""
            />
          </div>

          
          <div className="col-md-3">
            <AdminCard
              link="/admin/commodity-panel/rp-investor-bonus"
              class="blue"
              value="141$"
              label="RP INVESTOR
              BONUS"
              
              icon={bonus}
              progress="progress"
              progressbar="progress-bar"
              progressrole="progressbar"
              val="60%"
              arianow="10"
              ariamin="0"
              ariamax="100"
              progressval=""
            />
          </div>


         
        </div>
      </div>
    </>
  );
}

export default CommodityPannelDashboard;

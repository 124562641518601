import React, { useState, useEffect } from "react";
import axios from "axios";
import config from "../../../Config";
import PageTitle from "../PageTitle";
import AdminCard from "../AdminCard";

import user from "../../../assets/images/admin/icons/user.png";
import downline from "../../../assets/images/admin/icons/downline.png";
import advance_balance_transfer from "../../../assets/images/admin/icons/advance_balance_transfer.png";
import comodity from "../../../assets/images/admin/icons/comodity.png";

import total_packages from "../../../assets/images/admin/icons/total_packages.png";

import binary_packages from "../../../assets/images/admin/icons/binary_packages.png";

import promo_winner from "../../../assets/images/admin/icons/promo_winner.png";

import AdminHeader from "../AdminHeader";

   
   
import AdminRightsCheck from "../AdminRightsCheck";
// document.title= "Packages Dashboard"


function PackagesStatisticsDashboard() {
  return (
    <>
    <AdminRightsCheck />
         
      <PageTitle title="TOTAL TRANSFERS" />
      <div className="admin-content">
        <div className="row">
          <div className="col-md-3">
            <AdminCard
              link="#/"
              class="green"
              value="45$"
              label="TOTAL
PACKAGES
"
              icon={total_packages}
              progress="progress"
              progressbar="progress-bar"
              progressrole="progressbar"
              val="89%"
              arianow="10"
              ariamin="0"
              ariamax="100"
              progressval=""
            />
          </div>
          <div className="col-md-3">
            <AdminCard
              link="#/"
              class="brown"
              value="12"
              label="BINARY
PACKAGES
"
              icon={binary_packages}
              progress="progress"
              progressbar="progress-bar"
              progressrole="progressbar"
              val="10%"
              arianow="10"
              ariamin="0"
              ariamax="100"
              progressval=""
            />
          </div>
          <div className="col-md-3">
            <AdminCard
              link="#/"
              class="blue"
              value="35$"
              label="COMMODITY
PACKAGES
"
              icon={comodity}
              progress="progress"
              progressbar="progress-bar"
              progressrole="progressbar"
              val="30%"
              arianow="10"
              ariamin="0"
              ariamax="100"
              progressval=""
            />
          </div>
          <div className="col-md-3">
            <AdminCard
              link="#/"
              class="green"
              value="70$"
              label="PROMO
WINNER
"
              icon={promo_winner}
              progress="progress"
              progressbar="progress-bar"
              progressrole="progressbar"
              val="10%"
              arianow="10"
              ariamin="0"
              ariamax="100"
              progressval=""
            />
          </div>

          <div className="col-md-3">
            <AdminCard
              link="#/"
              class="red"
              value="70$"
              label="DOWNLINE
ACTIVATION"
              icon={downline}
              progress="progress"
              progressbar="progress-bar"
              progressrole="progressbar"
              val="80%"
              arianow="10"
              ariamin="0"
              ariamax="100"
              progressval=""
            />
          </div>

          <div className="col-md-3">
            <AdminCard
              link="#/"
              class="red"
              value="70$"
              label="BALANCE
TRANSFER
"
              icon={advance_balance_transfer}
              progress="progress"
              progressbar="progress-bar"
              progressrole="progressbar"
              val="40%"
              arianow="10"
              ariamin="0"
              ariamax="100"
              progressval=""
            />
          </div>

          <div className="col-md-3">
            <AdminCard
              link="#/"
              class="red"
              value="70$"
              label="NEW USER"
              icon={user}
              progress="progress"
              progressbar="progress-bar"
              progressrole="progressbar"
              val="10%"
              arianow="10"
              ariamin="0"
              ariamax="100"
              progressval=""
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default PackagesStatisticsDashboard;

import React, { useEffect } from "react";
import PageTitle from "./PageTitle";
import AdminCard from "./AdminCard";
import iconCalendar from "../../assets/images/admin/icons/calendar.png";
import iconDate2 from "../../assets/images/admin/icons/date2.png";
import iconDate3 from "../../assets/images/admin/icons/date3.png";
import iconProfit from "../../assets/images/admin/icons/profit.png";

import iconUsers from "../../assets/images/admin/icons/users.png";
import iconBlocked from "../../assets/images/admin/icons/blocked.png";
import iconPaid from "../../assets/images/admin/icons/paid.png";
import iconKyc from "../../assets/images/admin/icons/kyc.png";
import iconInactive from "../../assets/images/admin/icons/inactive.png";
import iconFreeUser from "../../assets/images/admin/icons/freeuser.png";
import iconActive from "../../assets/images/admin/icons/active.png";

import iconCash from "../../assets/images/admin/icons/cash.png";
import icon6x from "../../assets/images/admin/icons/6x.png";
import iconAdvanceBalanceTransfer from "../../assets/images/admin/icons/advance_balance_transfer.png";
import iconBalanceTransfer from "../../assets/images/admin/icons/balance_transfer.png";
import iconBinaryPackages from "../../assets/images/admin/icons/binary_packages.png";
import iconBonus from "../../assets/images/admin/icons/bonus.png";
import iconComodity from "../../assets/images/admin/icons/comodity.png";
import iconDeposit from "../../assets/images/admin/icons/deposit.png";
import iconDownline from "../../assets/images/admin/icons/downline.png";
import iconEarnings from "../../assets/images/admin/icons/earnings.png";
import iconExpire from "../../assets/images/admin/icons/expire.png";
import iconFree from "../../assets/images/admin/icons/free.png";
import iconFreeBalance from "../../assets/images/admin/icons/free_balance.png";
import iconFreeze from "../../assets/images/admin/icons/freeze.png";
import iconInvestUsBonusTransfer from "../../assets/images/admin/icons/investus_bonus_transfer.png";
import iconPackage from "../../assets/images/admin/icons/package.png";
import iconPackageBalanceTransfer from "../../assets/images/admin/icons/package_balance_transfer.png";
import iconPaidPackage from "../../assets/images/admin/icons/paid_package.png";
import iconPaidWithdrawl from "../../assets/images/admin/icons/paid_withdrawl.png";
import iconPending from "../../assets/images/admin/icons/pending.png";
import iconPendingWithdraw from "../../assets/images/admin/icons/pending_withdraw.png";
import iconPendingWithdrawl from "../../assets/images/admin/icons/pending_withdrawl.png";
import iconPromo from "../../assets/images/admin/icons/promo.png";
import iconPromoBalanceTransfer from "../../assets/images/admin/icons/promo_balance_transfer.png";
import iconPromoWinner from "../../assets/images/admin/icons/promo_winner.png";
import iconReceived from "../../assets/images/admin/icons/received.png";
import iconRecovered from "../../assets/images/admin/icons/recovered_balance.png";
import iconReversal from "../../assets/images/admin/icons/reversal.png";
import iconTotalBinary from "../../assets/images/admin/icons/total_binary.png";
import iconTotalPackages from "../../assets/images/admin/icons/total_packages.png";
import iconTotalTransfer from "../../assets/images/admin/icons/total_transfer.png";
import iconUser from "../../assets/images/admin/icons/user.png";
import iconUserEarning from "../../assets/images/admin/icons/user_earning.png";
import iconWithdrawl from "../../assets/images/admin/icons/withdrawl.png";






import Accordion from 'react-bootstrap/Accordion';
import AdminRightsCheck from "./AdminRightsCheck";

function DashboardOne(props) {

    useEffect(() => {
        document.title = "Admin";
    }, []);

    return (
        <>
           <AdminRightsCheck />
            <div className="container mt-3">
                <Accordion className="admin_card_accordian" defaultActiveKey="0">
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>
                            USER STATISTICS
                        </Accordion.Header>
                        <Accordion.Body>
                            <div className="row">
                                <div className="col-md-3">
                                    <AdminCard link="#/" class="blue" value="5" label="TOTAL USERS" icon={iconUsers} />
                                </div>
                                <div className="col-md-3">
                                    <AdminCard link="#/" class="green" value="15" label="PAID USER" icon={iconPaid} />
                                </div>
                                <div className="col-md-3">
                                    <AdminCard link="#/" class="red" value="3222" label="FREE USER" icon={iconFreeUser} />
                                </div>
                                <div className="col-md-3">
                                    <AdminCard link="#/" class="brown" value="178455" label="ACTIVE USER" icon={iconActive} />
                                </div>
                                <div className="col-md-3">
                                    <AdminCard link="#/" class="green" value="325" label="INACTIVE USER" icon={iconInactive} />
                                </div>
                                <div className="col-md-3">
                                    <AdminCard link="#/" class="red" value="325" label="BLOCK USER" icon={iconBlocked} />
                                </div>
                                <div className="col-md-3">
                                    <AdminCard link="#/" class="green" value="325" label="KYC APPROVAL REQUESTS" icon={iconKyc} />
                                </div>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                        <Accordion.Header>TOTAL FUND PANEL</Accordion.Header>
                        <Accordion.Body>
                            <div className="row">
                                <div className="col-md-3">
                                    <AdminCard link="#/" class="blue" value="5" label="USER CASH BALANCE" icon={iconCash} />
                                </div>
                                <div className="col-md-3">
                                    <AdminCard link="#/" class="blue" value="15" label="TOTAL RECEIVED BONUS" icon={iconReceived} />
                                </div>
                                <div className="col-md-3">
                                    <AdminCard link="#/" class="green" value="3222" label="TOTAL PAID BONUS" icon={iconPaid} />
                                </div>
                                <div className="col-md-3">
                                    <AdminCard link="#/" class="red" value="178455" label="USER PENDING BONUS" icon={iconPending} />
                                </div>
                                <div className="col-md-3">
                                    <AdminCard link="#/" class="green" value="325" label="TOTAL PACKAGE" icon={iconPackage} />
                                </div>
                                <div className="col-md-3">
                                    <AdminCard link="#/" class="green" value="325" label="TOTAL DEPOSIT" icon={iconDeposit} />
                                </div>
                                <div className="col-md-3">
                                    <AdminCard link="#/" class="red" value="325" label="TOTAL WITHDRAWAL" icon={iconWithdrawl} />
                                </div>
                                <div className="col-md-3">
                                    <AdminCard link="#/" class="red" value="325" label="TOTAL PENDING WITHDRAWAL" icon={iconPendingWithdrawl} />
                                </div>
                                <div className="col-md-3">
                                    <AdminCard link="#/" class="brown" value="325" label="TOTAL USER EARNING" icon={iconEarnings} />
                                </div>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>


               

            </div>


        </>
    );
}

export default DashboardOne;
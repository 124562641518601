import React, { useState, useEffect } from "react";
import config from "../../../Config";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import { useNavigate } from "react-router-dom";
import { NavLink, Link } from "react-router-dom";
   
import dayjs from "dayjs";
import Pagination from "../../member/General/Pagination";

import Dropdown from "react-bootstrap/Dropdown";
import AdminRightsCheck from "../AdminRightsCheck";

function TotalPackageList(props) {
  const [AccessKey, setAccessKey] = useState(
    localStorage.getItem("AdminAccessKey")
  );

  const [showFilters, setShowFilters] = useState(true);

  const [UserID, setUserID] = useState(localStorage.getItem("AdminID"));
  const [isUpdated, setIsUpdated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [listOfRecord, setListOfRecord] = useState([]);
  const [packageName, setPackageName] = useState("");

  // pagination
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  let navigate = useNavigate();


  useEffect(() => {
    document.title = "List of Packages";

    setIsUpdated(false);
    setLoading(true);

    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      pageNo: pageNo,
      pageSize: pageSize,
      PackageName: packageName,
    };

    ////console.log(data);

    var api_config = {
      method: "post",
      url: config.base_url + "Users/GetPackagesList",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    //console.log(data);

    axios(api_config)
      .then(function (response) {
        if (response.data.status_code === 1) {

          //console.log("data", response.data);
          setListOfRecord(response.data.ListofRecords);
          setLoading(false);
          setTotalRecords(response.data.totalRecords);
        }
        else if (response.data.status_code === 0 && response.data.status_message === "Invalid Access Key." || response.data.status_code === 2) {

          navigate("/admin/login")

        }
        else {
             
        }

      })
      .catch(function (error) {
        //console.log(error);
      });
  }, [pageNo, pageSize, isUpdated, packageName]);

  const handlePageChange = (page) => {
    setPageNo(page);
  };

  const renderLedger = () => {
    if (listOfRecord != null && listOfRecord.length > 0) {
      let count = 1;
      return listOfRecord.map((item, index) => {
        ////console.log(item);
        return (
          <tr key={index}>
            <td>{count++ + pageSize * (pageNo - 1)}</td>
            <td>{item.SecretID}</td>
            <td>{item.User_Name}</td>
            <td>{item.AccountKey}</td>
            <td>{item.Name}</td>
            <td>{item.Email_Address}</td>
            <td>{dayjs(item.Created_Date).format('DD-MMM-YYYY')}</td>
            <td>{item.Amount}</td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td colSpan="8">
            <h4
              className="mt-3"
              style={{ fontSize: "15px", color: "rgba(0,0,0,0.6)" }}
            >
              No Record Exists
            </h4>
          </td>
        </tr>
      );
    }
  };
  return (
    <>
      <AdminRightsCheck />
      <div className="admin-content">

        <div className="row align-items-center">
          <div className="col-md-6">
            <div className="d-title m-0">
              List of Packages
            </div>
          </div>
          <div className="col-md-6 text-end">
            <div className="">
              <NavLink to="/admin/fund/dashboard">
                <button type="button" className="button button-primary">
                  Back
                </button>
              </NavLink>
              <button
                onClick={() => {
                  setShowFilters((prevIsActive) => !prevIsActive);
                }}
                className="button button-primary text-white ms-3"
                style={{ fontWeight: "500", display: "inline-flex", alignItems: "center" }}
              >
                {showFilters ? "Hide Filters" : "Show Filters"}
                <i className="ri-filter-3-line ms-3"></i>
              </button>
            </div>
          </div>
        </div>

        <div
          className={`collapse ${showFilters ? "show" : ""} `}
          id="collapseFilteres"
        >
          <div className="card card-table mb-3 mt-4">
            <div className="card-inner">
              <div className="form_default">
                <div className="row p-3">
                  <div className="col-md-3">
                    <div className="form-group">
                      <label> Select by Package Name</label>
                      <div className="inputwrap">
                        <select
                          id="dropdown"
                          className="form-select"
                          value={packageName}
                          onChange={(e) => setPackageName(e.target.value)}
                        >
                          <option value="" selected>
                            All
                          </option>
                          <option value="Bronze">Bronze</option>
                          <option value="Silver">Silver</option>
                          <option value="Gold">Gold</option>
                          <option value="Platinum">Platinum</option>
                          <option value="Titanium">Titanium</option>
                          <option value="Crystal">Crystal</option>
                          <option value="Diamond">Diamond</option>
                          <option value="Crown">Crown</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div className="card card-table mb-5 mt-3">
          <div className="card-inner">
            <table className="table">
              <thead>
                <tr>
                  <th>Sr. No</th>
                  <th>Secret ID</th>
                  <th>User Name</th>
                  <th>Account Key</th>
                  <th>Name</th>
                  <th>Email Address</th>
                  <th>Created Date</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <tr>
                    <td className="text-center" colSpan="8">
                      <Spinner animation="border" role="status"></Spinner>
                    </td>
                  </tr>
                ) : (
                  <>{renderLedger()}</>
                )}
              </tbody>
            </table>
          </div>
        </div>
        {totalRecords > 10 && (
          <div className="pagination-wrap">
            <div className="row align-items-center">
              <div className="col-md-2 form_default">
                <div className="d-flex align-items-center">
                  <label className="me-2" style={{ fontSize: "15px" }}>
                    Page size
                  </label>
                  <div className="form-group">
                    <select
                      className="form-select"
                      style={{ width: "100px" }}
                      onChange={(e) => {
                        setPageNo(1);
                        setPageSize(parseInt(e.target.value));
                      }}
                    >
                      <option value="10" selected>
                        10
                      </option>
                      <option value="20">20</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                      <option value="500">500</option>
                    </select>
                  </div>
                </div>
              </div>

              <Pagination
                pageNo={pageNo}
                pageSize={pageSize}
                totalRecords={totalRecords}
                onPageChange={handlePageChange}
              />
            </div>
          </div>
        )}
      </div>

    </>
  );
}

export default TotalPackageList;

import React, { useState, useEffect } from "react";
import axios from "axios";
import config from "../../../Config";
import PageTitle from "../PageTitle";
import AdminCard from "../AdminCard";

import paid from "../../../assets/images/admin/icons/paid.png";
import timeicon from "../../../assets/images/admin/icons/timeicon.png";

import date3 from "../../../assets/images/admin/icons/date3.png";

import date2 from "../../../assets/images/admin/icons/date2.png";
import date1 from "../../../assets/images/admin/icons/date1.png";

import AdminHeader from "../AdminHeader";

   
   
import AdminRightsCheck from "../AdminRightsCheck";

function TreeBonusDashboard() {
  return (
    <>
    <AdminRightsCheck />
         
      <PageTitle title="TREE BONUS PANEL" />
      <div className="admin-content">
        <div className="row">
          <div className="col-md-3">
            <AdminCard
              link="#/"
              class="green"
              value="45$"
              label="TOTAL PAID
              BONUS"
              icon={paid}
              progress="progress"
              progressbar="progress-bar"
              progressrole="progressbar"
              val="50%"
              arianow="10"
              ariamin="0"
              ariamax="100"
              progressval=""
            />
          </div>
          <div className="col-md-3">
            <AdminCard
              link="#/"
              class="red"
              value="2$"
              label="TOTAL PENDING
              BONUS"
              icon={timeicon}
              progress="progress"
              progressbar="progress-bar"
              progressrole="progressbar"
              val="60%"
              arianow="10"
              ariamin="0"
              ariamax="100"
              progressval=""
            />
          </div>
          <div className="col-md-3">
            <AdminCard
              link="#/"
              class="blue"
              value="41$"
              label="3 MONTH BEFORE 
"
              icon={date3}
              progress="progress"
              progressbar="progress-bar"
              progressrole="progressbar"
              val="80%"
              arianow="10"
              ariamin="0"
              ariamax="100"
              progressval=""
            />
          </div>
          <div className="col-md-3">
            <AdminCard
              link="#/"
              class="brown"
              value="43$"
              label="2 MONTH BEFORE
"
              icon={date2}
              progress="progress"
              progressbar="progress-bar"
              progressrole="progressbar"
              val="30%"
              arianow="10"
              ariamin="0"
              ariamax="100"
              progressval=""
            />
          </div>

          <div className="col-md-3">
            <AdminCard
              link="#/"
              class="blue"
              value="35$"
              label="1 MONTH BEFORE
"
              icon={date1}
              progress="progress"
              progressbar="progress-bar"
              progressrole="progressbar"
              val="95%"
              arianow="10"
              ariamin="0"
              ariamax="100"
              progressval=""
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default TreeBonusDashboard;

import React, { useState, useEffect } from "react";
import config from "../../../Config";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import { NavLink, Link } from "react-router-dom";

import Pagination from "../../member/General/Pagination";
import AdminRightsCheck from "../AdminRightsCheck";

function ManageGroup(props) {
  const [AccessKey, setAccessKey] = useState(localStorage.getItem("AdminAccessKey"));
  const [UserID, setUserID] = useState(localStorage.getItem("AdminID"));
  const [MemberType, setMemberType] = useState(
    localStorage.getItem("MemberType")
  );
  const [loading, setLoading] = useState(false);
  const [listOfLedger, setLedger] = useState([]);
  const [isUpdated, setIsUpdated] = useState(false);

  // pagination
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);

  useEffect(() => {
    document.title = "Manage Groups";

    setIsUpdated(false);
    setLoading(true);
    const dashboardParms = {
      AccessKey: AccessKey,
      UserID: UserID,
      MemberType: "Admin",
    };

    var api_config = {
      method: "post",
      url: config.base_url + "Security/GetSecurityGroups",
      headers: {
        "Content-Type": "application/json",
      },
      data: dashboardParms,
    };

    axios(api_config)
      .then(function (response) {
        //console.log(response.data);
        setLedger(response.data.ListofRecords);
        setLoading(false);
        setTotalRecords(response.data.totalRecords);
      })
      .catch(function (error) {
        //console.log(error);
      });
  }, [pageNo, pageSize, isUpdated]);

  const handlePageChange = (page) => {
    setPageNo(page);
  };

  const renderLedger = () => {
    if (listOfLedger != "" && localStorage.length > 0) {
      return listOfLedger.map((item, index) => {
        return (
          <tr key={index}>
            <td>{item.ID}</td>
            <td>{item.Group_Name}</td>
            <td>{item.Description}</td>
            <td>{item.NoofUsers}</td>
            <td>
              <ul
                className="inline-action"
                aria-labelledby="dropdownMenuButton1"
              >
                <li>
                  <NavLink
                    to={"/admin/security/manage-groups/edit/" + item.ID}
                    className="primary "
                  >
                    <i class="material-symbols-outlined">
                      edit
                    </i>
                  </NavLink>
                </li>
                <li><a typeof="button" className="red" onClick={(e) => deleteGroup(item.ID)} type="button">
                  <span class="material-symbols-outlined">
                    delete
                  </span>
                </a></li>
                <li>
                  <NavLink
                    className="black"
                    to={"/admin/security/manage-groups/right-assignment/" + item.ID}
                  >
                    <span class="material-symbols-outlined">
                      add_circle
                    </span>
                  </NavLink>
                </li>
              </ul>
            </td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td colSpan="8">
            <h4
              className="mt-3"
              style={{ fontSize: "15px", color: "rgba(0,0,0,0.6)" }}
            >
              No Record Exists
            </h4>
          </td>
        </tr>
      );
    }
  };

  const deleteGroup = (ID) => {
    const confirm = window.confirm("Are you sure you want to proceed ?");
    if (confirm) {
      const data = {
        AccessKey: AccessKey,
        UserID: UserID,
        ID: ID,
      };

      var api_config = {
        method: "post",
        url: config.base_url + "Security/DeleteSecurityGroup/",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(api_config)
        .then(function (response) {
          ////console.log(response.data);
          if (response.data.status_code == 1) {


            setIsUpdated(true);
          }
          else if (response.data.status_code == 0) {



          }
          // setIsUpdated(false);
        })
        .catch(function (error) {
          //console.log(error);
        });
    }
  };

  return (
    <>
      <AdminRightsCheck />

      <div className="admin-content">
        <div className="row align-items-center">
          <div className="col-md-6">
            <div className="d-title m-0">
              Group Management
            </div>
          </div>
          <div className="col-md-6 text-end">
            <div className="">
              <NavLink to="/admin/security/dashboard">
                <button type="button" className="button button-primary">
                  Back
                </button>
              </NavLink>
              <NavLink to="/admin/security/manage-groups/add">
                <button type="button" className="button button-primary  ms-3">
                  Add Group
                </button>
              </NavLink>
            </div>
          </div>
        </div>

        <div className="card card-table mb-5 mt-3">
          <div className="card-inner">
            <table className="table">
              <thead>
                <tr>
                  <th>Sr#</th>
                  <th>Group Name</th>
                  <th>Group Details</th>
                  <th>No of Users</th>
                  <th className="text-center">Action</th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <tr>
                    <td className="text-center" colSpan="10">
                      <Spinner animation="border" role="status"></Spinner>
                    </td>
                  </tr>
                ) : (
                  <>{renderLedger()}</>
                )}
              </tbody>
            </table>
          </div>
        </div>
        {totalRecords > 10 && (
          <div className="pagination-wrap">
            <div className="row align-items-center">
              <div className="col-md-2 form_default">
                <div className="d-flex align-items-center">
                  <label className="me-2" style={{ fontSize: "15px" }}>
                    Page size
                  </label>
                  <div className="form-group">
                    <select
                      className="form-select"
                      style={{ width: "100px" }}
                      onChange={(e) => {
                        setPageNo(1);
                        setPageSize(parseInt(e.target.value));
                      }}
                    >
                      <option value="10" selected>
                        10
                      </option>
                      <option value="20">20</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                      <option value="500">500</option>
                    </select>
                  </div>
                </div>
              </div>

              <Pagination
                pageNo={pageNo}
                pageSize={pageSize}
                totalRecords={totalRecords}
                onPageChange={handlePageChange}
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default ManageGroup;

import React, { useState, useEffect } from "react";
import config from "../../../Config";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import dayjs from "dayjs";
import Pagination from "../../member/General/Pagination";
import csvDownload from "json-to-csv-export";
import AdminRightsCheck from "../AdminRightsCheck";
import { message } from "antd";
import excel from "../../../assets/images/excel.svg";

function MysteryListing(props) {

    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AdminAccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("AdminID"));
    const [isUpdated, setIsUpdated] = useState(false);
    const [loading, setLoading] = useState(false);
    const [listOfLedger, setLedger] = useState([]);

    const [messageApi, contextHolder] = message.useMessage();

    // pagination
    const [pageNo, setPageNo] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalRecords, setTotalRecords] = useState(0);


    const [filterStartDate, setfilterStartDate] = useState(dayjs().format("YYYY-MM-DD"));
    const [filterEndDate, setfilterEndDate] = useState(dayjs().format("YYYY-MM-DD"));


    const [loaddata, setLoaddata] = useState(false);
    const [listOfLedgerforcvs, setLedgerforcvs] = useState([]);

    const [showFilters, setShowFilters] = useState(true);

    useEffect(() => {
        document.title = "Mystery Box Deductions";

        setIsUpdated(false);
        setLoading(true);

        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
            pageNo: pageNo,
            pageSize: pageSize,
            startDate: filterStartDate,
            endDate: filterEndDate
        };

        //console.log(data);

        var api_config = {
            method: "post",
            url: config.base_url + "Users/GetMystryBoxDeductions",
            headers: {
                "Content-Type": "application/json",
            },
            data: data,
        };
        axios(api_config)
            .then(function (response) {
                //console.log(response.data)
                if (response.data.status_code == 1) {
                    setLedger(response.data.ListofLedger);
                    setLoading(false);
                    setTotalRecords(response.data.totalRecords);
                    ////console.log(totalRecords);
                }
                else {
                    setLedger([]);
                    setTotalRecords(0);
                    setLoading(false);
                }
            })
            .catch(function (error) {
                //console.log(error);
            });
    }, [pageNo, pageSize, filterStartDate, filterEndDate]);


    const handlePageChange = (page) => {
        setPageNo(page);
    };


    const renderLedger = () => {
        if (listOfLedger != null && listOfLedger.length > 0) {
            let count = 1;
            return listOfLedger.map((item, index) => {
                //console.log(item);
                return (
                    <tr key={index}>
                        <td>{count++ + pageSize * (pageNo - 1)}</td>
                        <td>{dayjs(item.Transaction_Date).format("DD/MM/YYYY")}</td>
                        <td>{item.Full_Name}</td>
                        <td>{item.User_Name}</td>
                        <td>{item.Email_Address}</td>
                        <td>${item.Credit}</td>
                    </tr>
                );
            });
        } else {
            return (
                <tr>
                    <td colSpan="6">
                        <h4 className="mt-3 text-center" style={{ fontSize: "15px", color: "white" }}>
                            No Record Exists
                        </h4>
                    </td>
                </tr>
            );
        }
    };

    const handleSubmitForCvs = (event) => {
        setLoaddata(true);
        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
            pageNo: pageNo,
            pageSize: pageSize,
            startDate: filterStartDate,
            endDate: filterEndDate
        };

        //console.log(data);

        var api_config = {
            method: "post",
            url: config.base_url + "Users/GetMystryBoxDeductions",
            headers: {
                "Content-Type": "application/json",
            },
            data: data,
        };

        axios(api_config)
            .then(function (response) {
                //console.log(response.data);
                setLedgerforcvs(response.data.ListofLedger);
                let data = response.data.ListofLedger;
                let count = 0;
                const listOfLedgerNew = data.map(({ ...rest }) => ({
                    Serial_No: count++ + pageSize * (pageNo - 1),
                    Dated: dayjs(rest.Transaction_Date).format("DD/MM/YYYY"),
                    Full_Name: rest.Full_Name,
                    User_Name: rest.User_Name,
                    Email: rest.Email_Address,
                    Amount: rest.Credit ? `$${rest.Credit}` : '--',
                }));

                const dataToConvert = {
                    data: listOfLedgerNew,
                    filename: "Mystery Box Deductions",
                    delimiter: ",",
                    headers: [
                        "Sr. No",
                        "Dated",
                        "Full Name",
                        "User Name",
                        "Email",
                        "Amount",
                    ],
                };

                csvDownload(dataToConvert);
                setLoaddata(false);
            })
            .catch(function (error) {
                setLoaddata(false);
            });
    };



    return (
        <>
            {contextHolder}

            <AdminRightsCheck />

            <div className="admin-content">
                <div className="row align-items-center">
                    <div className="col-md-6">
                        <div className="d-title m-0">Mystery Box Deductions</div>
                    </div>
                    <div className="col-md-6 text-end">
                        <div className="">
                            <button
                                onClick={() => {
                                    setShowFilters((prevIsActive) => !prevIsActive);
                                }}
                                className="button button-primary  ms-3"
                                style={{
                                    fontWeight: "500",
                                    display: "inline-flex",
                                    alignItems: "center",
                                }}
                            >
                                {showFilters ? "Hide Filters" : "Show Filters"}
                                <i className="material-symbols-outlined">filter_list</i>
                            </button>
                        </div>
                    </div>
                </div>

                <div
                    className={`collapse ${showFilters ? "show" : ""} `}
                    id="collapseFilteres"
                >
                    <div className="card card-table mb-3 mt-4">
                        <div className="card-inner">
                            <div className="form_default">
                                <div className="row p-3 align-items-end">
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label htmlFor="">Start Date</label>
                                            <div className="inputwrap mt-2">
                                                <input
                                                    className="form-control"
                                                    type="date"
                                                    value={filterStartDate}
                                                    onChange={(e) => setfilterStartDate(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label htmlFor="">End Date</label>
                                            <div className="inputwrap mt-2">
                                                <input
                                                    className="form-control"
                                                    type="date"
                                                    value={filterEndDate}
                                                    onChange={(e) => setfilterEndDate(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    {loaddata ? (
                                        <div className="col-md-6 text-end">
                                            <div style={{ marginTop: "32px" }}>
                                                <Spinner animation="border" role="status"></Spinner>
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="col-md-6 text-end">
                                            <div className="form-group mt-4">
                                                <a
                                                    type="button"
                                                    onClick={handleSubmitForCvs}
                                                    className="print-icon"
                                                >
                                                    <img src={excel} alt="Download excel file" />
                                                </a>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="card card-table mb-5 mt-3">
                    <div className="card-inner">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Sr. No</th>
                                    <th>Dated</th>
                                    <th>Full Name</th>
                                    <th>User Name</th>
                                    <th>Email</th>
                                    <th>Amount</th>
                                </tr>
                            </thead>
                            <tbody>
                                {loading ? (
                                    <tr>
                                        <td className="text-center" colSpan="6">
                                            <Spinner animation="border" role="status"></Spinner>
                                        </td>
                                    </tr>
                                ) : (
                                    <>{renderLedger()}</>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
                {totalRecords > 10 && (
                    <div className="pagination-wrap">
                        <div className="row align-items-center">
                            <div className="col-md-2 form_default">
                                <div className="d-flex align-items-center">
                                    <label className="me-2" style={{ fontSize: "15px" }}>
                                        Page size
                                    </label>
                                    <div className="form-group">
                                        <select
                                            className="form-select"
                                            style={{ width: "100px" }}
                                            onChange={(e) => {
                                                setPageNo(1);
                                                setPageSize(parseInt(e.target.value));
                                            }}
                                        >
                                            <option value="10" selected>
                                                10
                                            </option>
                                            <option value="20">20</option>
                                            <option value="50">50</option>
                                            <option value="100">100</option>
                                            <option value="500">500</option>
                                            <option value="1000">1000</option>
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <Pagination
                                pageNo={pageNo}
                                pageSize={pageSize}
                                totalRecords={totalRecords}
                                onPageChange={handlePageChange}
                            />
                        </div>
                    </div>
                )}
            </div>
        </>
    );
}

export default MysteryListing
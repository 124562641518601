import React, { useState, useEffect } from "react";
import config from "../../../Config";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import { useNavigate } from "react-router-dom";
import { NavLink, Link } from "react-router-dom";
   
import dayjs from "dayjs";
import Pagination from "../../member/General/Pagination";

import Dropdown from "react-bootstrap/Dropdown";
import AdminRightsCheck from "../AdminRightsCheck";

function CoinPayment(props) {
  const [AccessKey, setAccessKey] = useState(
    localStorage.getItem("AdminAccessKey")
  );
  const [UserID, setUserID] = useState(localStorage.getItem("AdminID"));

  //   const [isUpdated, setIsUpdated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [listOfLedger, setLedger] = useState([]);
  const [companyName, setcompanyName] = useState("CoinPayments");
  const [status, setStatus] = useState("Success");
  const [paymentMode, setpaymentMode] = useState("All");

  // pagination
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [paymode, setPaymode] = useState("BTC");

  const [showFilters, setShowFilters] = useState(true);

  let navigate = useNavigate();
  useEffect(() => {
    document.title = "Coin Payments";

    // setIsUpdated(false);
    setLoading(true);

    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      pageNo: pageNo,
      pageSize: pageSize,
      PaymentCompany: companyName,
      Status: status,

      PaymentMode: paymentMode,
    };

    //console.log(data);

    var api_config = {
      method: "post",
      url: config.base_url + "Users/GetDeposits",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        if (response.data.status_code === 1) {
          //console.log("data", response.data.ListofRecords);
          setLedger(response.data.ListofRecords);
          setLoading(false);
          setTotalRecords(response.data.totalRecords);
          //    //console.log(response.data.totalRecords);
        } else if (
          (response.data.status_code === 2 &&
            response.data.status_message === "Invalid Request Parameters") ||
          response.data.status_code === 0
        ) {
          navigate("/admin/login");
        } else {
             
        }
      })
      .catch(function (error) {
        //console.log(error);
      });
  }, [pageNo, pageSize, status, paymode, companyName, status, paymentMode]);

  const handlePageChange = (page) => {
    setPageNo(page);
  };

  const renderLedger = () => {
    if (listOfLedger != null && listOfLedger.length > 0) {
      let count = 1;
      return listOfLedger.map((item, index) => {
        //console.log(item);
        return (
          <tr key={index}>
            <td>{count++ + pageSize * (pageNo - 1)}</td>
            <td>{dayjs(item.Dated).format("DD/MM/YYYY")}</td>
            <td>{item.MemberID}</td>
            <td>{item.PaymentCompany}</td>
            <td>{item.PaymentID}</td>
            <td>{item.PaymentMode}</td>
            <td>{item.ReceivedUSD}</td>
            <td>{item.AmountUSD}</td>
            <td>{item.AmountCurrency}</td>
            <td>{item.Status}</td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td colSpan="9">
            <h4
              className="mt-3"
              style={{ fontSize: "15px", color: "rgba(0,0,0,0.6)" }}
            >
              No Record Exists
            </h4>
          </td>
        </tr>
      );
    }
  };
  return (
    <>
      <AdminRightsCheck />

      <div className="admin-content">
          <div className="row align-items-center">
            <div className="col-md-6">
              <div className="d-title m-0">
              COIN PAYMENTS
              </div>
            </div>
            <div className="col-md-6 text-end">
              <div className="">
                <NavLink to="/admin/deposit/dashboard">
                  <button type="button" className="button button-primary">
                    Back
                  </button>
                </NavLink>
                <button
                  onClick={() => {
                    setShowFilters((prevIsActive) => !prevIsActive);
                  }}
                  className="button button-primary text-white ms-3"
                  style={{ fontWeight: "500", display: "inline-flex", alignItems: "center" }}
                >
                  {showFilters ? "Hide Filters" : "Show Filters"}
                  <i className="ri-filter-3-line ms-3"></i>
                </button>
              </div>
            </div>
          </div>

          <div
            className={`collapse ${showFilters ? "show" : ""} `}
            id="collapseFilteres"
          >
            <div className="card card-table mb-3 mt-4">
              <div className="card-inner">
                <div className="form_default">
                  <div className="row p-3">
                    <div className="col-md-3">
                      <div className="form-group">
                        <label htmlFor="">Select Company</label>
                        <div className="inputwrap">
                          <select
                            id="dropdown"
                            className="form-select"
                            value={companyName}
                            onChange={(e) => setcompanyName(e.target.value)}
                          >
                            <option value="All" selected>All</option>
                            <option value="NowPayments"> Now Payments </option>
                            <option value="CoinPayments"> Coin Payments </option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <label htmlFor="">Select Status</label>
                        <div className="inputwrap">
                          <select
                            id="dropdown"
                            className="form-select"
                            value={status}
                            onChange={(e) => setStatus(e.target.value)}
                          >
                            <option value="Success"> Successs </option>
                            <option value="Pending"> Pending </option>
                            <option value="Failed"> Failed </option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <label htmlFor="">Select Payment Mode</label>
                        <div className="inputwrap">
                          <select
                            id="dropdown"
                            className="form-select"
                            value={paymentMode}
                            onChange={(e) => setpaymentMode(e.target.value)}
                          >
                            <option value="All"> All </option>
                            <option value="BTC"> BTC </option>
                            <option value="ETH"> ETH </option>
                            <option value="TRX"> TRX </option>
                            <option value="USDT"> USDT </option>
                            <option value="TRC20"> TRC20 </option>
                            <option value="USDTTRC20"> USDTTRC20 </option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="card card-table mb-5 mt-3">
            <div className="card-inner">
              <table className="table">
                <thead>
                  <tr>
                    <th>Sr. No</th>
                    <th>Date</th>
                    <th> Member ID</th>

                    <th>Payment Company </th>
                    <th>Payment ID </th>
                    <th>Payment Mode </th>
                    <th>Received USD </th>
                    <th>Amount USD </th>

                    <th>Amount Currency </th>
                    <th>Status </th>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <tr>
                      <td className="text-center" colSpan="9">
                        <Spinner animation="border" role="status"></Spinner>
                      </td>
                    </tr>
                  ) : (
                    <>{renderLedger()}</>
                  )}
                </tbody>
              </table>
            </div>
          </div>
          {totalRecords > 10 && (
            <div className="pagination-wrap">
              <div className="row align-items-center">
                <div className="col-md-2 form_default">
                  <div className="d-flex align-items-center">
                    <label className="me-2" style={{ fontSize: "15px" }}>
                      Page size
                    </label>
                    <div className="form-group">
                      <select
                        className="form-select"
                        style={{ width: "100px" }}
                        onChange={(e) => {
                          setPageNo(1);
                          setPageSize(parseInt(e.target.value));
                        }}
                      >
                        <option value="10" selected>
                          10
                        </option>
                        <option value="20">20</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                        <option value="500">500</option>
                      </select>
                    </div>
                  </div>
                </div>

                <Pagination
                  pageNo={pageNo}
                  pageSize={pageSize}
                  totalRecords={totalRecords}
                  onPageChange={handlePageChange}
                />
              </div>
            </div>
          )}
        </div>
      </>
      );
}

      export default CoinPayment;

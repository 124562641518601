import React, { useState, useEffect } from "react";
import axios from "axios";
import config from "../../../Config";
import PageTitle from "../PageTitle";
import AdminCard from "../AdminCard";

import promo from "../../../assets/images/admin/icons/promo.png";

import paid_package from "../../../assets/images/admin/icons/paid_package.png";
import kyc from "../../../assets/images/admin/icons/kyc.png";

import free from "../../../assets/images/admin/icons/free.png";

import freeze from "../../../assets/images/admin/icons/freeze.png";

import expire from "../../../assets/images/admin/icons/expire.png";

   
   
import AdminRightsCheck from "../AdminRightsCheck";

function TotalPackagesDashboard() {
  return (
    <>
    <AdminRightsCheck /> 
         
      <PageTitle title="TOTAL PACKAGES" />
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-3">
            <AdminCard
              link="#/"
              class="blue"
              value="500$"
              label="TOTAL PROMO
WINNER"
              icon={promo}
              progress="progress"
              progressbar="progress-bar"
              progressrole="progressbar"
              val="10%"
              arianow="10"
              ariamin="0"
              ariamax="100"
              progressval=""
            />
          </div>

          <div className="col-md-3">
            <AdminCard
              link="#/"
              class="green"
              value="15$"
              label="TOTAL PAID
PACKAGES"
              icon={paid_package}
              progress="progress"
              progressbar="progress-bar"
              progressrole="progressbar"
              val="10%"
              arianow="10"
              ariamin="0"
              ariamax="100"
              progressval=""
            />
          </div>

          <div className="col-md-3">
            <AdminCard
              link="#/"
              class="green"
              value="15$"
              label="TOTAL LEADER
PACKAGES"
              icon={kyc}
              progress="progress"
              progressbar="progress-bar"
              progressrole="progressbar"
              val="10%"
              arianow="10"
              ariamin="0"
              ariamax="100"
              progressval=""
            />
          </div>

          <div className="col-md-3">
            <AdminCard
              link="#/"
              class="brown"
              value="10$"
              label="TOTAL FREE
PACKAGES"
              icon={free}
              progress="progress"
              progressbar="progress-bar"
              progressrole="progressbar"
              val="10%"
              arianow="10"
              ariamin="0"
              ariamax="100"
              progressval=""
            />
          </div>

          <div className="col-md-3">
            <AdminCard
              link="#/"
              class="red"
              value="20$"
              label="TOTAL FREEZE
PACKAGES"
              icon={freeze}
              progress="progress"
              progressbar="progress-bar"
              progressrole="progressbar"
              val="40%"
              arianow="10"
              ariamin="0"
              ariamax="100"
              progressval=""
            />
          </div>

          <div className="col-md-3">
            <AdminCard
              link="#/"
              class="red"
              value="20$"
              label="TOTAL EXPIRE
PACKAGES"
              icon={expire}
              progress="progress"
              progressbar="progress-bar"
              progressrole="progressbar"
              val="10%"
              arianow="10"
              ariamin="0"
              ariamax="100"
              progressval=""
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default TotalPackagesDashboard;

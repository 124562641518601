import React, { useState, useEffect } from "react";
import axios from "axios";
import config from "../../../Config";
import PageTitle from "../PageTitle";
import AdminCard from "../AdminCard";
import iconUsers from "../../../assets/images/admin/icons/users.png";
import iconBlocked from "../../../assets/images/admin/icons/blocked.png";
import iconPaid from "../../../assets/images/admin/icons/paid.png";
import iconKyc from "../../../assets/images/admin/icons/kyc.png";
import iconInactive from "../../../assets/images/admin/icons/inactive.png";
import iconFreeUser from "../../../assets/images/admin/icons/freeuser.png";
import iconActive from "../../../assets/images/admin/icons/active.png";
// import AdminHeader from "../AdminHeader";
   
import Spinner from "react-bootstrap/Spinner";
import { useNavigate } from "react-router-dom";
import AdminRightsCheck from "../AdminRightsCheck";


   

function UserStatisticsDashboard(props) {
  const [AccessKey, setAccessKey] = useState(
    localStorage.getItem("AdminAccessKey")
  );
  const [UserID, setUserID] = useState(localStorage.getItem("AdminID"));
  const [loading, setLoading] = useState(false);
  const [UserStatics, setUserStatics] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    document.title = "User STATISTICS";
    setLoading(true);
    const data = {
      UserID: UserID,
      AccessKey: AccessKey,
    };

    var api_config = {
      method: "post",
      url: config.base_url + "Users/GetUserStatistics",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        //console.log(response.data);

        if (response.data.status_code == 1) {
          setUserStatics(response.data);
        } else if (
          (response.data.status_code === 0 &&
            response.data.status_message === "Invalid Access Key.") ||
          response.data.status_code === 2
        ) {
         
          navigate("/admin/login");
        } else {
             
        }

        setLoading(false);
      })
      .catch(function (error) {
            
      });
  }, []);

  return (
    <>
    <AdminRightsCheck />
    {loading ? (
                            <div className="d-flex justify-content-center"style={{marginTop:"20%"}}>
                              <Spinner
                                animation="border"
                                role="status"
                              ></Spinner>
                            </div>
                          ) : (
                            <>
    
         
      <PageTitle title="USER STATISTICS" />
      {UserStatics && (
        <div className="admin-content">
          <div className="row">
            <div className="col-md-3">
              <AdminCard
                link="/admin/userstatistics/Total-user"
                class="blue"
                value={UserStatics.totalUsers}
                label="TOTAL USERS"
                icon={iconUsers}
              />
            </div>
            <div className="col-md-3">
              <AdminCard
                link="/admin/userstatistics/userstatus/Paid"
                class="green"
                value={UserStatics.paidUsers}
                label="PAID USER"
                icon={iconPaid}
              />
            </div>
            <div className="col-md-3">
              <AdminCard
                link="/admin/userstatistics/userstatus/Free"
                class="red"
                value={UserStatics.freeUsers}
                label="FREE USER"
                icon={iconFreeUser}
              />
            </div>
            <div className="col-md-3">
              <AdminCard
                link="/admin/userstatistics/userstatus/Active"
                class="brown"
                value={UserStatics.activeUsers}
                label="ACTIVE USER"
                icon={iconActive}
              />
            </div>
            <div className="col-md-3">
              <AdminCard
                link="/admin/userstatistics/userstatus/Inactive"
                class="green"
                value={UserStatics.InactiveUsers}
                label="INACTIVE USER"
                icon={iconInactive}
              />
            </div>
            <div className="col-md-3">
              <AdminCard
                link="/admin/userstatistics/userstatus/Blocked"
                class="red"
                value={UserStatics.blockedUsers}
                label="BLOCK USER"
                icon={iconBlocked}
              />
            </div>
            <div className="col-md-3">
              <AdminCard
                link="/admin/userstatistics/KycRequests"
                class="green"
                value={UserStatics.kycPending}
                label="KYC APPROVAL REQUESTS"
                icon={iconKyc}
              />
            </div>

            <div className="col-md-3">
              <AdminCard
                link="/admin/userstatistics/KYCApproved"
                class="green"
                value={UserStatics.kycApproved}
                label="KYC APPROVED"
                icon={iconKyc}
              />
            </div>


          </div>
        </div>
      )}
      </>
                          )
      }
    </>
  );
}

export default UserStatisticsDashboard;

import React, { useState, useEffect } from "react";
import axios from "axios";
import config from "../../../Config";
import PageTitle from "../PageTitle";
import AdminCard from "../AdminCard";
import Spinner from "react-bootstrap/Spinner";

import paid from "../../../assets/images/admin/open_ticket.svg";
import close from "../../../assets/images/admin/close.svg";
import pending_ticket from "../../../assets/images/admin/pending_ticket.svg";

import date3 from "../../../assets/images/admin/ticket.svg";

import date2 from "../../../assets/images/admin/icons/date2.png";
import date1 from "../../../assets/images/admin/icons/date1.png";

import AdminHeader from "../AdminHeader";

   
   
import AdminRightsCheck from "../AdminRightsCheck";

function SupportDashboard() {


    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AdminAccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("AdminID"));
    const [Open, setOpen] = useState("");
    const [NewTickets, setNewTickets] = useState("");
    const [Close, setClose] = useState("");
    const [Pending, setPending] = useState("");
    const [loading, setLoading] = useState(false);


    useEffect(() => {
        document.title = "Support";
        setLoading(true);
        const data = {
            AccessKey: AccessKey,
            UserID: UserID,

        };

        var api_config = {
            method: 'post',
            url: config.base_url + 'Users/GetSupportDashboard',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        //console.log("data", data)

        axios(api_config)
            .then(function (response) {

                if (response.data.status_code == 1) {
                    setPending(response.data.totalPending);
                    setClose(response.data.totalClosed);
                    setOpen(response.data.totalOpen);
                    setNewTickets(response.data.totalNew);
                }

                setLoading(false);
            })
            .catch(function (error) {
                //console.log(error);
            });



    }, []);



    return (
        <>
            <AdminRightsCheck />
               
            <PageTitle title="SUPPORT" />
            <div className="admin-content">
                {
                    loading ?
                        <>
                            <Spinner animation="border" role="status"></Spinner>
                        </>
                        :
                        <>
                            <div className="row">
                                <div className="col-md-3">
                                    <AdminCard
                                        link="/admin/support/new-ticket"
                                        class="brown"
                                        value={NewTickets}
                                        label="New Tickets"
                                        icon={date3}
                                        progress="progress"
                                        progressbar="progress-bar"
                                        progressrole="progressbar"
                                        val="50%"
                                        arianow="10"
                                        ariamin="0"
                                        ariamax="100"
                                        progressval=""
                                    />
                                </div>
                                <div className="col-md-3">
                                    <AdminCard
                                        link="/admin/support/open-ticket"
                                        class="green"
                                        value={Open}
                                        label="Open Tickets"
                                        icon={paid}
                                        progress="progress"
                                        progressbar="progress-bar"
                                        progressrole="progressbar"
                                        val="10%"
                                        arianow="10"
                                        ariamin="0"
                                        ariamax="100"
                                        progressval=""
                                    />
                                </div>
                                <div className="col-md-3">
                                    <AdminCard
                                        link="/admin/support/pending-ticket"
                                        class="blue"
                                        value={Pending}
                                        label="Pending Tickets"
                                        icon={pending_ticket}
                                        progress="progress"
                                        progressbar="progress-bar"
                                        progressrole="progressbar"
                                        val="50%"
                                        arianow="10"
                                        ariamin="0"
                                        ariamax="100"
                                        progressval=""
                                    />
                                </div>
                                <div className="col-md-3">
                                    <AdminCard
                                        link="/admin/support/close-ticket"
                                        class="red"
                                        value={Close}
                                        label="Closed Tickets"
                                        icon={close}
                                        progress="progress"
                                        progressbar="progress-bar"
                                        progressrole="progressbar"
                                        val="30%"
                                        arianow="10"
                                        ariamin="0"
                                        ariamax="100"
                                        progressval=""
                                    />
                                </div>
                            </div>
                        </>
                }

            </div>
        </>
    );
}

export default SupportDashboard;

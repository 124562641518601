import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import axios from "axios";
   
import config from "../../../Config";
import UserGroupDropDown from "./UserGroupDropDown";
import AdminRightsCheck from "../AdminRightsCheck";

const AddUser = () => {

  let navigate = useNavigate()

  const [AccessKey, setAccessKey] = useState(
    localStorage.getItem("AdminAccessKey")
  );
  const [UserID, setUserID] = useState(localStorage.getItem("AdminID"));

  const [loading, setLoading] = useState(false);
  const [email, setemail] = useState('');
  const [fullName, setfullName] = useState();
  const [password, setpassword] = useState();
  const [retype_password, setretype_password] = useState();
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [isValidEmail, setIsValidEmail] = useState(true);
  const[disabled,setDisabled] = useState(true)
  const [group, setgroup] = useState();
  useEffect(()=>{
    document.title = "Add User";

  },[])
  const handleSubmit = (event) => {
    
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;

    setLoading(true);
    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      Email_Address: email,
      Full_Name: fullName,
      Password: password,
      Group_ID: group,
    };

    //console.log(data);

    var api_config = {
      method: "post",
      url: config.base_url + "Security/AddUser",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    if (!isValidEmail || !passwordsMatch) {
     
    
    } 
    else {
      axios(api_config)
      .then(function (response) {
        ////console.log(response.data);
        if (response.data.status_code == 1) {
             
          
          setTimeout(() => {
            navigate("/admin/security/manage-users")
          }, 2000)
        }
        else if (response.data.status_code == 2 && response.data.status_message == "Invalid Request Parameters") {

             
        }
        else if (response.data.status_code == 0 && response.data.status_message == "User Name Already Exists.") {

             
        }

        else {
             
        }

        setLoading(false);
      })
      .catch(function (error) {
        setLoading(false);
            
      });
    }
   

    // setValidated(true);
  };


  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setpassword(newPassword);
    setPasswordsMatch(newPassword === retype_password);
    setDisabled(newPassword !== retype_password);

  };

  const handleRetypePasswordChange = (e) => {
    const newRetypedPassword = e.target.value;
    setretype_password(newRetypedPassword);
    setPasswordsMatch(password === newRetypedPassword);
    setDisabled(newRetypedPassword !== password);
  };

  const validateEmail = (email) => {
    // You can implement your own email validation logic here
    // For simplicity, let's use a basic regex pattern
    const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return pattern.test(email);
  };
  const handleEmailChange = (e) => {
    const newEmail = e.target.value;
    setemail(newEmail);
    setIsValidEmail(validateEmail(newEmail));
  };
  const checkEmail = () => {
    setIsValidEmail(validateEmail(email));
  }

  return (
    <>
    <AdminRightsCheck />
         
      <div className="admin-content">


      <div className="row align-items-center mb-3">
          <div className="col-md-6">
            <div className="d-title m-0">
            Add New User
            </div>
          </div>
          <div className="col-md-6 text-end">
           
              <NavLink to="/admin/security/manage-users">
                <button type="button" className="button button-secondary">
                  Back
                </button>
              </NavLink>
             
             
            
          </div>
        </div>



       
        <div className="card card-table  ">

          <form onSubmit={handleSubmit} >
          <div className="card-inner p-3">
            <div className="row mb-1">
              <div className="col-md-3">
                <h6 className="">Full Name</h6>
                <div className="inputwrap">
                  <input
                    type="text"
                    // style={{ width: "120px" }}
                    className="form-control w-100"
                    // value={fullName}
                    onChange={(e) => setfullName(e.target.value)}
                  />
                </div>
              </div>


              <div className="col-md-3">
                <h6 className="">Email Address</h6>
                <div className="inputwrap">
                  <input
                    type="email"
                    className="form-control w-100"
                    // defaultValue={User.Email_Address}
                    onChange={handleEmailChange}
                    onBlur={checkEmail}
                    required
                    autocomplete="off"
                   
                  />

                </div>
                {/* {!isValidEmail && //console.log("wrong email enter")} */}
                {!isValidEmail && <p>Invalid email address</p>}
              </div>

              <div className="col-md-3">
                <h6 className="">Password</h6>
                <div className="inputwrap">
                  <input
                    type="password"

                    className="form-control w-100"
                    defaultValue={password}
                    onChange={handlePasswordChange}
                    required
                  />
                </div>
              </div>

              <div className="col-md-3">
                <h6 className="">Retype Password</h6>
                <div className="inputwrap">
                  <input
                    type="password"

                    className="form-control w-100"
                    defaultValue={retype_password}
                    onChange={handleRetypePasswordChange}
                    required
                  />
                </div>
                {!passwordsMatch && <div className="mt-1">Passwords do not match</div>}
              </div>

            </div>
            <div className="row mt-2">
              <div className="col-md-3">
                <h6 className="">Select User Group</h6>
                <select
                  id="dropdown"
                  className="form-select w-100"
                  required
                  onChange={(e) => setgroup(e.target.value)}
                >
                  <option value="">Please Select</option>
                  <UserGroupDropDown />
                </select>
              </div>
              <div className="text-end">
                <button
                  type="submit"
                  className="button button-primary"
                 
                  disabled ={disabled}
                >
                  Add
                </button>
              </div>
            </div>
          </div>
          </form>
          
        </div>
      </div>
    </>
  );
};

export default AddUser;

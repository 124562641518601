import React from "react";
import Modal from "react-bootstrap/Modal";
import { Form } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import AdminRightsCheck from "../AdminRightsCheck";
const MystryBoxModel = ({
    showMystryBox,
    handleAddMystryBox,
    handleCloseMystryBox,
    validatedMystryBox,
    loadingUpdateBtnMystryBox,
  selectedMember,
}) => {
  return (
    <>
       <AdminRightsCheck />
      <Modal className="theme-modal" show={showMystryBox} onHide={handleCloseMystryBox}>
        <Modal.Header closeButton>
          <Modal.Title>Add New MystryBox For {selectedMember.User_Name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validatedMystryBox} onSubmit={handleAddMystryBox}>
            <div style={{ padding: "20px" }}>
              <div className="form-group mb-3">
                <label>Dated</label>
                <input
                  type="Date"
                  // defaultValue={selectedlistOfLedger.No_Of_Leaves}
                  name="MystryBox_Date"
                  className="form-control"
                  required
                />
              </div>
              <div className="form-group mb-3">
                <label>Deduction Amount</label>
                <input
                  type="number"
                  // defaultValue={selectedlistOfLedger.No_Of_Leaves}
                  name="MystryBox_DeductionAmount"
                  className="form-control"
                  required
                />
              </div>
              <div className="form-group mb-3">
                <label>Profit Amount</label>
                <input
                  type="number"
                  // defaultValue={selectedlistOfLedger.No_Of_Leaves}
                  name="ProfitAmount_Date"
                  className="form-control"
                  required
                />
              </div>

              {loadingUpdateBtnMystryBox ? (
                <div className="text-center">
                  <Spinner animation="border" role="status"></Spinner>
                </div>
              ) : (
                <button type="submit" className="button button-primary w-100">
                  Add MystryBox
                </button>
              )}
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default MystryBoxModel;

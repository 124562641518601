import React, { useState, useEffect } from "react";
import config from "../../../Config";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import { NavLink, Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
// import { RenderHTMLStringWithoutDiv } from "../../Common/Util";
import Pagination from "../../member/General/Pagination";
import AdminRightsCheck from "../AdminRightsCheck";

function ManagePackage(props) {
  const [AccessKey, setAccessKey] = useState(
    localStorage.getItem("AdminAccessKey")
  );
  const [UserID, setUserID] = useState(localStorage.getItem("AdminID"));
  const [MemberType, setMemberType] = useState(localStorage.getItem("MemberType"));

  const [isUpdated, setIsUpdated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [listOfLedger, setLedger] = useState([]);

  // pagination
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);

  const [showFilters, setShowFilters] = useState(true);

  const [IsDelect, setIsDelect] = useState(false);

  useEffect(() => {
    document.title = "Mag Holdings Admin";

    setIsUpdated(false);
    setLoading(true);

    const data = {
      AccessKey: AccessKey,
      Member_Type: MemberType,
      UserID: UserID,
      pageNo: pageNo,
      pageSize: pageSize,
    };

    //console.log(data);

    var api_config = {
      method: "post",
      url: config.base_url + "Package/GetAllPackages",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        //console.log(response.data);
        if (response.data.status_code === 1) {
          setLedger(response.data.ListofPackages);
          setLoading(false);
        } else if (
          (response.data.status_code === 2 &&
            response.data.status_message === "Invalid Request Parameters") ||
          response.data.status_code === 0
        ) {
          toast.error(response.data.status_message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          toast.error(response.data.status_message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(function (error) {
        //console.log(error);
      });
  }, [pageNo, pageSize, isUpdated, IsDelect]);

  const handlePageChange = (page) => {
    setPageNo(page);
  };

  const renderLedger = () => {
    if (listOfLedger != null && listOfLedger.length > 0) {
      let count = 1;
      return listOfLedger.map((item, index) => {
        //console.log(item);
        return (
          <tr key={index}>
            <td>{item.ID}</td>
            <td>{item.Name}</td>
            <td>{item.Amount}</td>
            <td>{item.ROI}</td>
            <td>{(item.Details)}</td>
            <td>
              <ul
                className="inline-action"
              >
                <li>
                  <NavLink
                    to={"/admin/package/update-package/" + item.ID}
                    className="primary "
                  >
                    <span class="material-symbols-outlined">
                      edit
                    </span>
                  </NavLink>
                </li>
                <li><Link className="red" onClick={(e) => DelectItem(item.ID)} type="button">
                  <span class="material-symbols-outlined">
                    delete
                  </span>
                </Link></li>
                {/* <li>
                  <NavLink
                    className="black"
                    to={"/admin/package/add-package"}
                  >
                   <span class="material-symbols-outlined">
                    add
                  </span>
                  </NavLink>
                </li> */}
              </ul>
            </td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td colSpan="8">
            <h4
              className="mt-3"
              style={{ fontSize: "15px", color: "rgba(0,0,0,0.6)" }}
            >
              No Data Found
            </h4>
          </td>
        </tr>
      );
    }
  };


  const DelectItem = (ID) => {
    if (window.confirm('Are you sure to delete the Package')) {

      setIsDelect(true);
      const data = {
        AccessKey: AccessKey,
        UserID: UserID,
        Member_Type: MemberType,
        ID: ID,
      };

      //console.log('data', data);

      var api_config = {
        method: 'post',
        url: config.base_url + 'Package/DeletePackage',
        headers: {
          'Content-Type': 'application/json'
        },
        data: data
      };


      axios(api_config)
        .then(function (response) {

          if (response.data.status_code == 1) {
            toast.success(response.data.status_message, {
              position: toast.POSITION.TOP_RIGHT,
            });
            setIsDelect(false);
          }
          else {
            toast.error(response.data.status_message, {
              position: toast.POSITION.TOP_RIGHT,
            });
            setIsDelect(false);
          }
        })
        .catch(function (error) {
          toast.error(error.data.status_message, {
            position: toast.POSITION.TOP_RIGHT,
          });

        });
    }
  };

  return (
    <>
      <ToastContainer />
      <AdminRightsCheck />
      {/* <ReactTooltip /> */}
      <div className="admin-content">
        <div className="row align-items-center gy-3">
          <div className="col-md-6">
            <div className="d-title m-0">
              Manage Packages
            </div>
          </div>
          <div className="col-md-6 text-md-end text-center d-none">
            <div className="">
              <NavLink to={`/admin/package/add-package`} className="button button-primary text-white ms-3"
                style={{ fontWeight: "500", display: "inline-flex", alignItems: "center" }}
              >
                Add Package
                <i className="ri-add-line ms-3"></i>
              </NavLink>
            </div>
          </div>
        </div>

        <div className="card card-table mb-5 mt-3">
          <div className="card-inner responsive-table">
            <table className="table">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Name</th>
                  <th>Amount</th>
                  <th>ROI</th>
                  <th>Details</th>
                  <th className="text-center">Action</th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <tr>
                    <td className="text-center" colSpan="9">
                      <Spinner animation="border" role="status"></Spinner>
                    </td>
                  </tr>
                ) : (
                  <>{renderLedger()}</>
                )}
              </tbody>
            </table>
          </div>
        </div>
        {totalRecords > 10 && (
          <div className="pagination-wrap">
            <div className="row align-items-center">
              <div className="col-md-2 form_default">
                <div className="d-flex align-items-center">
                  <label className="me-2" style={{ fontSize: "15px" }}>
                    Page size
                  </label>
                  <div className="form-group">
                    <select
                      className="form-select"
                      style={{ width: "100px" }}
                      onChange={(e) => {
                        setPageNo(1);
                        setPageSize(parseInt(e.target.value));
                      }}
                    >
                      <option value="10" selected>
                        10
                      </option>
                      <option value="20">20</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                      <option value="500">500</option>
                      <option value="1000">1000</option>
                    </select>
                  </div>
                </div>
              </div>

              <Pagination
                pageNo={pageNo}
                pageSize={pageSize}
                totalRecords={totalRecords}
                onPageChange={handlePageChange}
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default ManagePackage
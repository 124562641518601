import React, { useState, useEffect } from "react";
import config from "../../../Config";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import { useParams } from "react-router-dom";
import { Form } from "react-bootstrap";
import AdminRightsCheck from "../AdminRightsCheck";




function UpdateProfilebyAdmin(props) {

    const AccessKey = localStorage.getItem("AdminAccessKey");
    const UserID = localStorage.getItem("AdminID");
    const [validated, setValidated] = useState(false);
    let params = useParams();

    const [loading, setLoading] = useState(false);
    const [loadingUpdate, setLoadingUpdate] = useState(false);
    const [user, setUser] = useState({});
    const [IsUpdated, setIsUpdated] = useState(false);

    useEffect(() => {
        document.title = "Update Profile";
        setLoading(true);
        setIsUpdated(false);
        const data = {

            AccessKey: AccessKey,
            UserID: UserID,
            ID: params.id
        };


        var api_config = {
            method: "post",
            url: config.base_url + "Users/GetMemberByMemberID",
            headers: {
                "Content-Type": "application/json",
            },
            data: data,
        };

        axios(api_config)
            .then(function (response) {
                // console.log(response.data);
                if (response.data.status_code == 1) {
                    setUser(response.data.Member);
                    setLoading(false);
                }

                else {
                    setLoading(false);
                }
            })
            .catch(function (error) {
                //console.log(error);
            });

    }, [IsUpdated]);


    const UpdateProfile = (event) => {
        event.preventDefault();
        event.stopPropagation();

        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            setLoadingUpdate(true);
            const data = {
                AccessKey: AccessKey,
                UserID: UserID,
                ID: params.id,
                Full_Name: event.target.Full_Name.value,
                Email_Address: event.target.Email_Address.value,
                MobileNo: event.target.MobileNo.value,
                CNIC_No: event.target.CNIC_No.value,
                RankName: '',
                Email_Address_Verified: event.target.Email_Address_Verified.value,
                RankRewardGiven: '',
                RestrictUplineProfit: event.target.RestrictUplineProfit.checked,
                RestrictWithdrawl: event.target.RestrictWithdrawl.checked,
                HideInUpLine: event.target.HideInUpLine.checked,
                RestrictROItransfer: event.target.RestrictROItransfer.checked,
            };

            var api_config = {
                method: "post",
                url: config.base_url + "Users/UpdateProfile",
                headers: {
                    "Content-Type": "application/json",
                },
                data: data,
            };

            axios(api_config)
                .then(function (response) {
                    //console.log(response.data);
                    if (response.data.status_code == 1) {
                        setIsUpdated(true);
                        setLoadingUpdate(false);
                    }

                    else {
                        setLoadingUpdate(false);
                    }
                })
                .catch(function (error) {
                    //console.log(error);
                });
        }
    }


    return (
        <>
            <AdminRightsCheck />
            <div className="admin-content">
                <div className="d-title m-0">Update Profile</div>
                {
                    loading ?
                        <div className="text-center mt-5">
                            <Spinner />
                        </div>
                        :
                        <>

                            <Form noValidate validated={validated} onSubmit={UpdateProfile}>
                                <div className="row mt-4">
                                    <div className="col-md-3">
                                        <div className="form-gorup mb-3">
                                            <label className="form-label">Name</label>
                                            <input type="text" defaultValue={user.Full_Name} name="Full_Name" className="form-control" />
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-gorup mb-3">
                                            <label className="form-label">Email</label>
                                            <input type="email" defaultValue={user.Email_Address} name="Email_Address" className="form-control" />
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-gorup mb-3">
                                            <label className="form-label">Mobile</label>
                                            <input type="text" defaultValue={user.Mobile_No} name="MobileNo" className="form-control" />
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-gorup mb-3">
                                            <label className="form-label">CNIC</label>
                                            <input type="text" defaultValue={user.CNIC_No} name="CNIC_No" className="form-control" />
                                        </div>
                                    </div>
                                    {/* <div className="col-md-3">
                                        <div className="form-gorup mb-3">
                                            <label className="form-label">Rank</label>
                                            <input type="text" defaultValue={user.RankName} name="RankName" className="form-control" />
                                        </div>
                                    </div> */}



                                    <div className="col-md-3">
                                        <div className="form-gorup mb-3">
                                            <label className="form-label">Email Verified</label>
                                            <select className="form-select" name="Email_Address_Verified" defaultValue={user.Email_Address_Verified}>
                                                <option value={true}>Yes</option>
                                                <option value={false}>No</option>
                                            </select>
                                        </div>
                                    </div>
                                    {/* <div className="col-md-3">
                                        <div className="form-gorup mb-3">
                                            <label className="form-label">Current Reward Paid?</label>
                                            <select className="form-select" name="RankRewardGiven" defaultValue={user.RankRewardGiven}>
                                                <option value={true}>Yes</option>
                                                <option value={false}>No</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-gorup mb-3">
                                            <label className="form-label">Leader Commission Rate</label>
                                            <input type="text" name="" defaultValue={user.CommissionRate} className="form-control" />
                                        </div>
                                    </div> */}
                                </div>

                                <div className="row">
                                    <div className="col-md-3">
                                        <div className="form-check mb-3">
                                            <input className="form-check-input"
                                                defaultChecked={user.RestrictUplineProfit}
                                                name="RestrictUplineProfit"
                                                type="checkbox" id="flexCheckDefault1" />
                                            <label className="form-check-label" htmlFor="flexCheckDefault1">
                                                Restrict Upline Profit
                                            </label>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-check mb-3">
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                name="RestrictWithdrawl"
                                                defaultChecked={user.RestrictWithdrawl}
                                                id="flexCheckDefault2" />
                                            <label className="form-check-label" htmlFor="flexCheckDefault2">
                                                Restrict Withdrawal
                                            </label>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-check mb-3">
                                            <input className="form-check-input"
                                                type="checkbox"
                                                name="HideInUpLine"
                                                defaultChecked={user.HideInUpLine}
                                                id="flexCheckDefault3" />
                                            <label className="form-check-label" htmlFor="flexCheckDefault3">
                                                Hide in Upline
                                            </label>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-check mb-3">
                                            <input className="form-check-input"
                                                defaultChecked={user.RestrictROItransfer}
                                                name="RestrictROItransfer"
                                                type="checkbox" id="flexCheckDefault4" />
                                            <label className="form-check-label" htmlFor="flexCheckDefault4">
                                                Restrict ROI Transfer
                                            </label>
                                        </div>
                                    </div>

                                </div>
                                <div className="text-end">
                                    {
                                        loadingUpdate ?
                                            <Spinner />
                                            :
                                            <button type="submit" className="button button-primary">Update Profile</button>
                                    }

                                </div>
                            </Form>
                        </>
                }

            </div>
        </>
    );
}

export default UpdateProfilebyAdmin;